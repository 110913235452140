import React from 'react'
import './index.less';
import { List, InputItem, WhiteSpace, Button, Toast, WingBlank, Picker } from 'antd-mobile';
import { getUrlParam, FilterParam } from "../../utils/utils";
import DocumentTitle from './../../components/DocumentTitle';
import Axios from './../../axios';

const ListItem = List.Item;


export default class AgentBankCard extends React.Component {
  state = {
  }
  componentWillMount() {
    this.bankInfoReuest();
    //从链接获取参数
    let urlparams = this.props.location.search;
    let id = getUrlParam("id", urlparams);
    let haveCard = getUrlParam("haveCard", urlparams);
    let parkingCardId = id;
    let notCard = haveCard ? false : true;
    this.setState({
      parkingCardId,
      notCard
    })
  }
  //各搜索框值
  handleChange = (e, type) => {
    this.setState({
      [type]: e
    })
    if (type == "mobile") {
      if (/^1(3|4|5|6|7|8|9)\d{9}$/.test(e)) {
        let params = {
          mobile: e
        }
        this.verifyMobileReuest(params);
      }
    }
  }
  //选择银行
  handleChoiceBank = (e) => {
    console.log(e)
    this.setState({
      choiceBank: e
    })
  }
  //确定
  handleSubmit = () => {
    const { choiceBank, realname, cardNumber } = this.state;
    if (!choiceBank) {
      Toast.fail("请选择银行！", 1);
      return;
    }
    if (!realname) {
      Toast.fail("请填写姓名！", 1);
      return;
    }
    if (!cardNumber) {
      Toast.fail("请填写银行卡号！", 1);
      return;
    }
    let params = {
      bankCode: choiceBank[0],
      realname,
      cardNumber,
    }
    this.updateBankInfoReuest(params);
  }

  /***查询用户银行卡信息***/
  bankInfoReuest = () => {
    Toast.loading("加载中...", 0)
    Axios.AgtServiceRequest({
      method: 'get',
      url: '/wx/user/bankInfo',
      params: "",
    }).then((data) => {
      console.log(data)
      let bankInfo = data.data;
      let bankCode = bankInfo.bankCode;
      let realname = bankInfo.realname;
      let cardNumber = bankInfo.cardNumber;
      let choiceBank = bankCode ? [bankCode] : [];
      this.setState({
        choiceBank,
        realname,
        cardNumber
      })
    }).catch((error) => {
      console.log(error);
    })
  }
  /***更新用户银行卡信息***/
  updateBankInfoReuest = (params) => {
    Toast.loading("请求中...", 0)
    Axios.AgtServiceRequest({
      method: 'post',
      url: '/wx/user/bankInfo',
      params: params,
    }).then((data) => {
      console.log(data)
      const { parkingCardId, notCard } = this.state;
      this.returnCardReuest({ parkingCardId, notCard })
    }).catch((error) => {
      console.log(error);
    })
  }
  /***用户退卡退费***/
  returnCardReuest = (params) => {
    Toast.loading("请求中...", 0)
    Axios.AgtServiceRequest({
      method: 'post',
      url: '/wx/parkingCard/parkingCardRefund',
      params: params,
    }).then((data) => {
      console.log(data)
      Toast.success("退费/退卡申请已成功，请等待工作人员审核！", 2, () => {
        window.open(`/#/agentCard`, '_self')
      })
    }).catch((error) => {
      console.log(error);
    })
  }
  render() {
    const bankInfo = [
      {
        label: "工商银行",
        value: "1002",
      }, {
        label: "农业银行",
        value: "1005",
      }, {
        label: "中国银行",
        value: "1026",
      }, {
        label: "建设银行",
        value: "1003",
      }, {
        label: "招商银行",
        value: "1001",
      }, {
        label: "邮储银行",
        value: "1066",
      }, {
        label: "交通银行",
        value: "1020",
      }, {
        label: "浦发银行",
        value: "1004",
      }, {
        label: "民生银行",
        value: "1006",
      }, {
        label: "兴业银行",
        value: "1009",
      }, {
        label: "平安银行",
        value: "1010",
      }, {
        label: "中信银行",
        value: "1021",
      }, {
        label: "华夏银行",
        value: "1025",
      }, {
        label: "广发银行",
        value: "1027",
      }, {
        label: "光大银行",
        value: "1022",
      }, {
        label: "北京银行",
        value: "4836",
      }, {
        label: "宁波银行",
        value: "1056",
      }
    ]
    return (
      <DocumentTitle title="填写银行账户">
        <div className="full-bank-info">
          <List>
            <Picker
              data={bankInfo}
              cols={1}
              value={this.state.choiceBank}
              onChange={this.handleChoiceBank}
            >
              <ListItem arrow="horizontal">银行名称</ListItem>
            </Picker>
            <InputItem
              value={this.state.realname}
              onChange={(e) => this.handleChange(e, "realname")}
              type="text"
              placeholder="请输入姓名"
            >姓名</InputItem>
            <InputItem
              value={this.state.cardNumber}
              type="text"
              onChange={(e) => this.handleChange(e, "cardNumber")}
              placeholder="请输入银行账号"
            >银行账号</InputItem>

          </List>
          <WhiteSpace size="xl" />
          <WingBlank>
            <Button type="primary" onClick={this.handleSubmit}>确定</Button>
            <WhiteSpace size="xl" />
            <div className="txt-danger">实退金额小于0时，将不会产生退款交易数据</div>
          </WingBlank>
        </div>
      </DocumentTitle>
    );
  }
}


