import React from 'react'
import './index.less';
import {getUrlParam} from "../../utils/utils";

export default class Error extends React.Component {

    componentWillMount() {
        //从链接获取参数
        let urlparams = this.props.location.search;
        let userId = getUrlParam("userId",urlparams);
        let token = getUrlParam("token",urlparams);
        let msg = getUrlParam("msg",urlparams);
        if (userId) {
            sessionStorage.setItem("X-User-Id",userId);
        }
        if (token) {
            sessionStorage.setItem("Authorization",token);
        }
        this.setState({
            msg
        })
    }
    render() {
        const { msg } = this.state;
        return (
            <div>
                <div className="nomatch-icon">
                    <img alt="" src="/assets/error.png"/>
                </div>
                <div className="hint">{msg?msg:"页面出错！"}</div>
            </div>
        );
    }
}