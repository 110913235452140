import React from 'react'
import './index.less';
import { createForm,formShape } from 'rc-form';
import { List, InputItem,WhiteSpace,Button,Toast,WingBlank,Tabs,Picker,TextareaItem,Checkbox,Flex,SearchBar,Modal  } from 'antd-mobile';
import DocumentTitle from './../../components/DocumentTitle';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import Axios from './../../axios';
import defaultImg from "../../assets/default_img.png"
import {getUrlParam} from "../../utils/utils";
const moment = extendMoment(Moment);
const AgreeItem = Checkbox.AgreeItem;
const FlexItem = Flex.Item;
const Item = List.Item;

export default class RefundDetails extends React.Component {
    state = {
    }
    componentWillMount() {
        const urlparams = this.props.location.search;
        const id = getUrlParam("id",urlparams);
        this.detailsReuest({parkingCardRefundId:id});
    }
    /***记录详情***/
    detailsReuest = (params) => {
        Toast.loading("加载中...", 0)
        Axios.serviceRequest({
            method: 'get',
            url: '/wx/parkingCard/parkingCardRefund/detail',
            params: params,
        }).then((data) => {
            //console.log(data)
            let details = data.data;
            this.setState({
                details
            })
        }).catch((error) => {
            console.log(error);
        })
    }
    render() {
        let details = this.state.details || {};
        const prePaymentTypeCofig = {
            "NONE":"临停",
            "MONTH":"包月",
            "QUARTER":"包季",
            "HALF_YEAR":"包半年",
            "YEAR":"包年",
        }
        return (
            <DocumentTitle title="退款详情">
                <div className="common-item-lr refund-details">
                    <div className="txt-danger state">
                        <WingBlank>
                            { details.state == "INIT" ? <span className="txt-danger">待审核</span> : null }
                            { details.state == "APPROVED" ? <span className="txt-primary">审核通过</span> : null }
                            { details.state == "REFUSE" ? <span className="txt-danger">审核拒绝</span> : null }
                            { details.state == "TRANSFER_PENDING" ? <span className="txt-primary">转账中</span> : null }
                            { details.state == "TRANSFER_FAIL" ? <span className="txt-danger">转账失败</span> : null }
                            { details.state == "TRANSFER_SUCCESS" ? <span className="txt-primary">转账成功</span> : null }
                        </WingBlank>
                    </div>
                    <List>
                        <Item extra={details.vehicleTypeName} wrap={true}>车辆类型</Item>
                        <Item extra={details.prePaymentType?prePaymentTypeCofig[details.prePaymentType]:""} wrap={true}>套餐类型</Item>
                        {/*<Item extra={`￥${details.prePaymentAmount||0}`} wrap={true}>套餐金额</Item>*/}
                        <Item extra={`￥${details.entityCardCashPledge||0}`} wrap={true}>实体卡押金</Item>
                        <Item extra={`￥${details.serviceCharge||0}`} wrap={true}>手续费</Item>
                        <Item extra={`￥${details.totalAmount||0}`} wrap={true}>应退金额</Item>
                        <Item extra={`￥${details.amount||0}`} wrap={true}>实退金额</Item>
                        <Item extra={details.createTime} wrap={true}>发起时间</Item>
                        <Item wrap={true}>审核意见</Item>
                        <div className="suggestion">
                            <WingBlank>
                                {details.remark}
                            </WingBlank>
                        </div>
                    </List>
                    <WhiteSpace size="xl"/>
                    <div className="txt-danger">实退金额小于0时，将不会产生退款交易数据</div>
                    <WhiteSpace size="xl"/>
                    <WingBlank>
                        {
                            details.entityCardCashPledge?<div className="txt-danger">应退金额中已包括退还的实体卡押金</div>:null
                        }
                    </WingBlank>
                </div>
            </DocumentTitle>
        );
    }
}





