import React from 'react'
import { NavLink } from 'react-router-dom';
import './index.less';
import { List, InputItem, WhiteSpace, Button, Toast, WingBlank, Checkbox } from 'antd-mobile';
import { getUrlParam, FilterParam, getBase64 } from "../../utils/utils";
import DocumentTitle from './../../components/DocumentTitle';
import tempImg from './../../assets/temp-parking.png';
import tempCardImg from './../../assets/temp.png';
import NavBar from './../../components/Navbar';


import monthImg from './../../assets/month.png';
import moneyImg from './../../assets/money.png';
import refundImg from './../../assets/staff.png';



export default class ChooseType extends React.Component {
  state = {
    avatar: {}
  }
  componentWillMount() {
    //从链接获取参数
    let urlparams = this.props.location.search;
    let lockId = getUrlParam("lockId", urlparams);
    let userId = getUrlParam("userId", urlparams);
    let token = getUrlParam("token", urlparams);
    let userCenter = getUrlParam("userCenter", urlparams);
    let siteId = getUrlParam("siteId", urlparams);
    let openId = getUrlParam("openId", urlparams);
    let nickname = getUrlParam("nickname", urlparams);
    let headPortraitUrl = getUrlParam("headPortraitUrl", urlparams);
    this.setState({
      lockId, userId, token, userCenter, siteId, openId, nickname, headPortraitUrl
    })
  }
  handleChooseType = (e) => {
    console.log(e)
    const { lockId, userId, token, userCenter, siteId, openId, nickname, headPortraitUrl } = this.state;
    let parkType = e;
    if (token) {
      if (parkType === 2) {
        window.open('/#/charge/first', '_self');
      } else {
        window.open(`/#/pay?lockId=${lockId}&userId=${userId}&token=${token}&siteId=${siteId}&parkType=${parkType}`, '_self')
      }
    } else {
      //走注册流程
      window.open(`/#/register?siteId=${siteId}&lockId=${lockId}&openId=${openId}&nickname=${nickname}&headPortraitUrl=${headPortraitUrl}&userCenter=${userCenter}&parkType=${parkType}`, '_self');
    }
  }

  render() {
    return (
      <DocumentTitle title="智能车库">
        <div className="choose-type">
          <div className="main-wrapper">
            <div className="temp-top" onClick={() => this.handleChooseType(0)}>
              <img src={tempImg} className="temp-img" />
              <div className="temp-text">临时停车</div>
            </div>
            <h2 className="title-text">
              特惠停车卡
            </h2>

            <div className="parking-card" onClick={() => this.handleChooseType(1)}>
              <img src={monthImg} />
              <div className="card-center">
                <p className="title">包月停车（新办卡）</p>
                <p className="sub-title">包月停车，省心划算</p>
              </div>
              <Button className="card-btn" size="small" type="primary">去办卡</Button>
            </div>

            <h2 className="title-text">
              我的工具
            </h2>
            <div className="tools-wrap">
              <div className="tools-item" onClick={() => { window.open(`/#/agentLogin`, '_self') }}>
                <img src={moneyImg} />
                <div>代缴费/代绑卡</div>

              </div>
              <div className="tools-item" onClick={() => { window.open(`/#/refundRecord`, '_self') }}>
                <img src={refundImg} />
                <div>退款记录</div>
              </div>

            </div>

            {/* <div className="top-box" onClick={() => { window.open('/#/myCenter', '_self'); }}>
              <img className="icon-user" alt="" src="/assets/icon_home.png" />
              <span>我的卡包（续费）</span>
              <img className="icon-arrow" alt="" src="/assets/icon_homeright.png" />
            </div>
            <div className="item" onClick={() => this.handleChooseType(0)} style={{ paddingTop: 0 }}>
              <div className="box">
                <div className="left">
                  <img alt="" src="/assets/icon_blue.png" />
                </div>
                <div className="right">
                  <p className="title">临时停车</p>
                  <p className="sub-title">临时停放非机动车</p>
                  <div className="btn-box">
                    <Button size="small" type="primary">临停缴费</Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="item" onClick={() => this.handleChooseType(1)}>
              <div className="box">
                <div className="left">
                  <img alt="" src="/assets/icon_red.png" />
                </div>
                <div className="right">
                  <p className="title">包月停车</p>
                  <p className="title">（新办卡）</p>
                  <p className="sub-title">包月停车，省心划算</p>
                  <div className="btn-box">
                    <Button size="small" type="primary">包月缴费</Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="item" onClick={() => this.handleChooseType(2)}>
              <div className="box">
                <div className="left">
                  <img alt="" src="/assets/icon_chongdian.png" />
                </div>
                <div className="right">
                  <p className="title">充电</p>
                  <p className="sub-title">仅支持电动车充电</p>
                  <div className="btn-box">
                    <Button size="small" type="primary">扫码充电</Button>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="user-info-box" onClick={() => { window.open('/#/myCenter/account', '_self'); }}>
              <span className="left">你好，用户{userInfo && userInfo.mobile}</span>
              <span className="right">我的账号</span>
              <img className="icon" src="/assets/right.png" alt="" />
            </div> */}
          </div>

          <NavBar defaultKey="parking" onChange={this.handleNavChange} />
        </div>
      </DocumentTitle>
    );
  }
}


