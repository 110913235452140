import React from 'react'
import './index.less';

export default class Nomatch extends React.Component {

    render() {
        return (
            <div>
                <div className="nomatch-icon">
                    <img alt="" src="/assets/no_data.png"/>
                </div>
                <div className="identify">404</div>
                <div className="hint">抱歉，你访问的页面不存在</div>
            </div>
        );
    }
}