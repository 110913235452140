import React from 'react'
import { HashRouter, Route, Switch, BrowserRouter } from 'react-router-dom'
import App from './App'
import Register from './pages/register'
import WechatLogin from './pages/register/wechatLogin'

import Main from './main'
import Common from './common'
import Nomatch from './pages/nomatch';
import Pay from './pages/pay'
import UnlockingResult from "./pages/unlockingResult";
import Renew from "./pages/renew";
import MyCenter from "./pages/myCenter";
import MyCard from "./pages/myCard";
import BindCard from "./pages/bindCard";
import AgentLogin from "./pages/agentLogin";
import AgentCenter from "./pages/agentCenter";
import AgentCard from "./pages/agentCard";
import Error from "./pages/error";
import AgentPay from "./pages/agentPay";
import AgentBindCard from "./pages/agentBindCard";
import AgentRenew from "./pages/agentRenew";
import CompleteInfo from "./pages/register/completeInfo";
import Rules from "./pages/register/rules";
import Standard from "./pages/register/standard";
import BankCard from "./pages/bankCard";
import AgentBankCard from "./pages/agentBankCard";
import RepairCard from "./pages/repairCard";
import AgentRepairCard from "./pages/agentRepairCard";
import MyCar from "./pages/myCar";
import RefundRecord from "./pages/refundRecord";
import RefundDetails from "./pages/refundRecord/refundDetails";
import ChooseType from "./pages/chooseType";
import chargeFirst from "./pages/charge/index";
import chargeSecond from "./pages/charge/second";
import chargeThird from "./pages/charge/third";
import Account from "./pages/myCenter/account";
import Avatar from "./pages/myCenter/avatar";
import MyHome from "./pages/myHome";
import AgentHome from "./pages/agentHome";
import AgentAccount from "./pages/agentCenter/account";
import AgentAvatar from "./pages/agentCenter/avatar";

export default class ERouter extends React.Component {

  render() {
    return (
      <HashRouter>
        <App>
          <Switch>
            <Route exact path="/register/completeInfo" component={CompleteInfo} />
            <Route exact path="/register/rules" component={Rules} />
            <Route exact path="/register/standard" component={Standard} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/wechatLogin" component={WechatLogin} />
            <Route exact path="/chooseType" component={ChooseType} />
            <Route exact path="/charge/first" component={chargeFirst} />
            <Route exact path="/charge/second" component={chargeSecond} />
            <Route exact path="/charge/third" component={chargeThird} />
            <Route path="/common" render={() =>
              <Common>
                {/*<Route path="/common/order/detail/:orderId" component={OrderDetail} />*/}
              </Common>
            }
            />
            <Route path="/" render={() =>
              <Main>
                <Switch>
                  <Route exact path='/pay' component={Pay} />
                  <Route exact path='/unlockingResult' component={UnlockingResult} />
                  <Route exact path='/renew' component={Renew} />
                  <Route exact path='/myCenter' component={MyCenter} />
                  <Route exact path='/myCenter/account' component={Account} />
                  <Route exact path='/myCenter/avatar' component={Avatar} />
                  <Route exact path='/myCard' component={MyCard} />
                  <Route exact path='/bindCard' component={BindCard} />
                  <Route exact path='/agentLogin' component={AgentLogin} />
                  <Route exact path='/agentCenter' component={AgentCenter} />
                  <Route exact path='/agentCenter/account' component={AgentAccount} />
                  <Route exact path='/agentCenter/avatar' component={AgentAvatar} />
                  <Route exact path='/agentCard' component={AgentCard} />
                  <Route exact path='/agentPay' component={AgentPay} />
                  <Route exact path='/agentBindCard' component={AgentBindCard} />
                  <Route exact path='/agentRenew' component={AgentRenew} />
                  <Route exact path='/bankCard' component={BankCard} />
                  <Route exact path='/agentBankCard' component={AgentBankCard} />
                  <Route exact path='/repairCard' component={RepairCard} />
                  <Route exact path='/agentRepairCard' component={AgentRepairCard} />
                  <Route exact path='/myCar' component={MyCar} />
                  <Route exact path='/refundRecord' component={RefundRecord} />
                  <Route exact path='/refundRecord/refundDetails' component={RefundDetails} />
                  <Route exact path='/myHome' component={MyHome} />
                  <Route exact path='/agentHome' component={AgentHome} />
                  <Route exact path='/error' component={Error} />
                  <Route component={Nomatch} />
                </Switch>
              </Main>
            } />
          </Switch>
        </App>
      </HashRouter>
    );
  }
}