import React, { Component } from 'react';
import './style/index.less'

class App extends Component {
    render() {
        return (
            <div className="page-box">
                {this.props.children}
            </div>
        );
    }
}
export default App;
