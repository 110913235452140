import React from 'react'
import './index.less';
import { createForm, formShape } from 'rc-form';
import { List, InputItem, WhiteSpace, Button, Toast, WingBlank, Tabs, Picker, TextareaItem, Checkbox, Flex, SearchBar } from 'antd-mobile';
import DocumentTitle from './../../components/DocumentTitle';
import Axios from './../../axios';
import { getUrlParam } from "../../utils/utils";
const ListItem = List.Item;
const AgreeItem = Checkbox.AgreeItem;
const FlexItem = Flex.Item;

export default class AgentBindCard extends React.Component {
  state = {
  }
  componentWillMount() {

  }
  handleChange = (e) => {
    this.setState({
      entityCardNo: e
    })
  }
  //绑定确定
  handlSubmit = () => {
    const { entityCardNo } = this.state;
    const urlparams = this.props.location.search;
    const id = getUrlParam("id", urlparams);
    if (!entityCardNo) {
      Toast.fail("请输入实体卡号！", 1);
      return false;
    }
    let params = {
      id,          //停车卡编号
      entityCardNo,
    }
    this.returnCardReuest(params);
  }
  /*绑卡请求*/
  returnCardReuest = (params) => {
    Toast.loading("绑卡中...", 0)
    Axios.AgtServiceRequest({
      method: 'post',
      url: '/wx/parkingCard/bindEntityCard',
      params: params,
    }).then((data) => {
      console.log(data)
      Toast.success("绑卡成功！", 1)
      window.open(`/#/agentCard`, '_self');
    }).catch((error) => {
      console.log(error);
    })
  }
  render() {
    return (
      <DocumentTitle title="代理绑卡">
        <div className="bind-card-box">
          <WingBlank>
            <div className="explain">绑定实体卡后，你可使用实体卡刷卡开门，如需退卡，申请成功后实体卡押金将返回你的微信账户</div>
          </WingBlank>
          <List>
            <InputItem
              type="text"
              placeholder="请输入实体卡号"
              clear
              onChange={this.handleChange}
            >实体卡</InputItem>
          </List>
          <WingBlank>
            <WhiteSpace size="xl" />
            <Button type="primary" onClick={this.handlSubmit}>绑定</Button>
            <WhiteSpace size="xl" />
          </WingBlank>
        </div>
      </DocumentTitle>
    );
  }
}





