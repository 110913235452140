import React from 'react'
import './index.less';
import {
    List,
    InputItem,
    WhiteSpace,
    Button,
    Toast,
    WingBlank,
    Tabs,
    Picker,
    TextareaItem,
    Checkbox,
    Flex,
    SearchBar
} from 'antd-mobile';
import DocumentTitle from './../../components/DocumentTitle';
import Axios from './../../axios';

const ListItem = List.Item;
const AgreeItem = Checkbox.AgreeItem;
const FlexItem = Flex.Item;


export default class ChoiceCts extends React.Component {
    state = {
        keywords: ""
    }

    componentWillMount() {
        this.sitesReuest();
    }
    handleChange = (e) => {
        const sitesData = this.state.sitesData || [];
        let keywords = e;
        let searchSites = [];
        for (let i = 0; i < sitesData.length; i++) {
            let list = sitesData[i].list;
            for (let j = 0; j < list.length; j++) {
                let item = list[j]
                //console.log(item)
                let name = item.name;
                if (name.indexOf(keywords) > -1) {
                    searchSites.push(item);
                }
            }
        }
        this.setState({
            keywords,
            searchSites,
        })
        //console.log(searchSites)
    }

    /***查询所有场地—分组***/
    sitesReuest = (params) => {
        //Toast.loading("加载中...", 0)
        Axios.serviceRequest({
            method: 'get',
            url: '/wx/site/group',
            params: params,
        }).then((data) => {
            //console.log(data)
            let sitesData = data.data;
            this.setState({
                sitesData
            })
        }).catch((error) => {
            console.log(error);
        })
    }

    render() {
        const sitesData = this.state.sitesData || [];
        const keywords = this.state.keywords;
        const searchSites = this.state.searchSites;
        return (
            <DocumentTitle title="选择场地">
                <div className="choice-courts-box">
                    <div className="search-box">
                        <SearchBar
                            /*value={this.state.value}*/
                            placeholder="请输入小区或场地名称"
                            onSubmit={value => console.log(value, 'onSubmit')}
                            onClear={value => console.log(value, 'onClear')}
                            onCancel={this.props.handleCancle}
                            showCancelButton
                            onChange={this.handleChange}
                        />
                    </div>
                    {
                        !keywords ?
                            <FullCts sitesData={sitesData} handleChoice={this.props.handleChoice} />
                            : null
                    }
                    {
                        keywords && searchSites && searchSites.length ?
                            <SearchResult searchSites={searchSites} handleChoice={this.props.handleChoice} />
                            : null
                    }
                    {
                        keywords && (!searchSites || searchSites.length === 0) ? < div className="no-result">没有匹配的结果</div> : null
                    }
                    {/*<SearchResult/>*/}
                    {/*<div class="no-result">没有匹配的结果</div>*/}
                </div>
            </DocumentTitle>
        );
    }
}

export class FullCts extends React.Component {
    state = {}

    componentWillMount() {
    }

    render() {
        const { sitesData } = this.props;
        return (
            <div className="full-courts-box">
                {
                    sitesData.map((item, index) => {
                        let list = item.list || [];
                        return (
                            <div className="group" key={item.title}>
                                <a className="group-name">{item.title}</a>
                                <ul className="courts-box">
                                    {
                                        list.map((_item, _index) => {
                                            return (
                                                <li key={_item.siteId} onClick={() => this.props.handleChoice(_item)}>{_item.name}</li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        )
                    })
                }
                {/*<div className="group">
                    <a  className="group-name">B</a>
                    <ul className="courts-box">
                        <li>比佳.加州新城</li>
                        <li>边佳.加州新城</li>
                    </ul>
                </div>*/}
            </div>
        );
    }
}

export class SearchResult extends React.Component {
    state = {}
    componentWillMount() {
    }
    render() {
        const { searchSites } = this.props;
        return (
            <div className="search-result-box">
                <div className="explain">找到以下小区或场地</div>
                <ul className="courts-box">
                    {
                        searchSites.map((item) => {
                            return (
                                <li key={item.siteId} onClick={() => this.props.handleChoice(item)}>{item.name}</li>
                            )
                        })
                    }
                </ul>
            </div>
        );
    }
}



