import React from 'react';
import { ImagePicker, WingBlank, SegmentedControl } from 'antd-mobile';
import img from "../../assets/avatar.png"
import "./index.less"
import Axios from "../../axios"
import {Toast} from "antd-mobile/lib/index";

export class ImgsUpload extends React.Component{
    state={
        files:[]
    }
    componentDidMount(){
    }
    handleChange = (_files, type, index)=>{
        let { files } = this.state;
            //console.log(_files, type, index);
            if (type=="add"){
                let file = _files[_files.length-1].file;
                let formData = new FormData();
                formData.append("file", file);
                formData.append("dir", "car-info");
                formData.append("open", true);
                Toast.loading("上传中...", 0)
                Axios.jsonRequest({
                    method:'post',
                    url:'/common/singleUpload',
                    params:formData,
                }).then((data)=>{
                    Toast.hide();
                    //console.log(data)
                    let info = data.data||{};
                    let obj = {
                        id:info.hash,
                        url:info.url,
                    }
                    files.push(obj);
                    this.setState({
                        files
                    },()=>{
                        this.props.onChange(files)
                    })
                }).catch((error)=>{
                    console.log(error);
                    // Toast.hide();
                })
            }else if (type=="remove") {
                this.setState({
                    files:_files
                },()=>{
                    this.props.onChange(_files)
                })
            }
    }
    render(){
        const { files } = this.state;
        let { max } = this.props;
        max = max||1;
        return(
            <WingBlank>
                <ImagePicker
                    files={files}
                    onChange={this.handleChange}
                    onImageClick={(index, fs) => console.log(index, fs)}
                    selectable={files.length < max}
                    multiple={false}
                />
            </WingBlank>
        )
    }
}