import React from 'react'
import { NavLink } from 'react-router-dom';
import './index.less';
import { List, InputItem,WhiteSpace,Button,Toast,WingBlank,Checkbox } from 'antd-mobile';
import { getUrlParam,FilterParam,getBase64 } from "../../utils/utils";
import DocumentTitle from './../../components/DocumentTitle';
import Axios from './../../axios';
import avatarImg from "../../assets/avatar.png";

const AgreeItem = Checkbox.AgreeItem;


export default class CompleteInfo extends React.Component {
    state = {
        avatar:{}
    }
    componentWillMount() {
        //从链接获取参数
        let urlparams = this.props.location.search;
        let userCenter = getUrlParam("userCenter",urlparams);
        if (userCenter) {
            this.setState({
                userCenter
            })
        }
    }
    handleChange = (e)=>{
        let file = e.target.files[0];
        console.log(file)
        let formData = new FormData();
        formData.append("file", file);
        formData.append("dir", "avatar");
        formData.append("open", false);
        Toast.loading("上传中...", 0)
        Axios.jsonRequest({
            method:'post',
            url:'/common/singleUpload',
            params:formData,
        }).then((data)=>{
            Toast.hide();
            console.log(data)
            let info = data.data||{};
            let key  = info.key;
            //let url  = info.url;
            getBase64(file, imageUrl => {
                //console.log(imageUrl)
                this.setState({
                    avatar:{
                        key,
                        url:imageUrl
                    }
                })
            });
        }).catch((error)=>{
            console.log(error);
            // Toast.hide();
        })
    }
    handleAgreeChange = (e)=>{
        //console.log(e.target.checked)
        this.setState({
            acceptAgreement:e.target.checked
        })
    }
    //下一步
    handleNext = ()=>{
        const { avatar,acceptAgreement } = this.state;
        let key = avatar.key;
        if (!key){
            Toast.fail("请上传清晰真实的个人头像照片！",1.5);
            return false;
        }
        let params = {
            acceptAgreement,
            realHeadPortrait:key
        }
        console.log(params)
        this.userInfoReuest(params)
    }

    /***更新用户附加信息***/
    userInfoReuest = (params) => {
        Toast.loading("加载中...", 0)
        Axios.serviceRequest({
            method: 'put',
            url: '/wx/user/attachInfo',
            params: params,
        }).then((data) => {
            console.log(data)
            let userCenter = this.state.userCenter;
            if (userCenter){
                window.open(`/#/myCenter`,'_self')
            } else {
                window.open(`/#/pay`,'_self')
            }
        }).catch((error) => {
            console.log(error);
        })
    }
    render() {
        const { avatar,acceptAgreement } = this.state;
        return (
            <DocumentTitle title="智能车库登录">
                <div className="register-firstStep-box">
                    <div className="img-info-upload">
                        <div className="img-box" style={{backgroundImage:`url(${avatar.url||avatarImg})`}}> <input type="file" accept="image/*" multiple onChange={this.handleChange}/></div>
                        <div className="txt">请上传清晰真实的个人面部照片</div>
                    </div>
                    <WhiteSpace size="xl"/>
                    <WingBlank>
                        <Button type="primary" disabled={!acceptAgreement} onClick={this.handleNext}>下一步</Button>
                    </WingBlank>
                    <div className="protocol">
                        <AgreeItem onChange={this.handleAgreeChange}>
                            <span className="txt">同意<NavLink to={`/register/rules`}>非机动车库管理规定</NavLink>与<NavLink to={`/register/standard`}>赔偿标准</NavLink></span>
                        </AgreeItem>
                    </div>
                </div>
            </DocumentTitle>
        );
    }
}


