import React from 'react'
import './index.less';
import {createForm, formShape} from 'rc-form';
import {
    List,
    InputItem,
    WhiteSpace,
    Button,
    Toast,
    WingBlank,
    Tabs,
    Picker,
    TextareaItem,
    Checkbox,
    Modal
} from 'antd-mobile';
import Moment from 'moment';
import {extendMoment} from 'moment-range';
import DocumentTitle from './../../components/DocumentTitle';
import { ImgsUpload } from '../../components/ImgUpload';
import ChoiceCts from './../choiceCts'
import Axios from './../../axios';
import {Base64} from 'js-base64';
import {
    monthSurplus,
    quarterSurplus,
    halfYearSurplus,
    yearSsurplus,
    monthDays,
    quarterDays,
    halfYearDays,
    yearDays,
    yearMonths,
    getUrlParam
} from "../../utils/utils";

const ListItem = List.Item;
const AgreeItem = Checkbox.AgreeItem;
const moment = extendMoment(Moment);
const wx = window.wx;
const tabs = [
    {title: '临停缴费'},
    {title: '充值缴费'},
];

export default class AgentPay extends React.Component {
    state = {
        isPop: false,
        activeTab: 1,
        times: 0,    //轮询次数
    }

    componentWillMount() {
        this.urlBase64Request();

        let urlparams = this.props.location.search;
        let lockId = getUrlParam("lockId", urlparams);
        let userId = getUrlParam("userId", urlparams);
        let token = getUrlParam("token", urlparams);
        let parkType = getUrlParam("parkType", urlparams);
        if (parkType!=null&&parkType!=undefined&&parkType!=''&&parkType!='null'&&parkType!='undefined'){
            this.setState({
                activeTab:parseInt(parkType)
            })
        }
        if (lockId) {
            sessionStorage.setItem("lockId", lockId);
        }
        if (userId) {
            sessionStorage.setItem("X-User-Id", userId);
        }
        if (token) {
            sessionStorage.setItem("Authorization", token);
        }
        let siteId = getUrlParam("siteId", urlparams) ? getUrlParam("siteId", urlparams) : sessionStorage.getItem("siteId");
        if (siteId && siteId != "null") {
            sessionStorage.setItem("siteId", siteId);
        }
        if (siteId && siteId != "null") {
            let params = {
                id: siteId,
            }
            this.initSiteDetailsReuest(params)
        }
    }

    //tab切换
    handleChange = (title, index) => {
        this.setState({
            activeTab: index
        })
    }
    //选择场地  弹窗选择场地
    handleChoiceSite = () => {
        this.setState({
            isPop: true
        })
    }
    //选择场地 关闭弹窗
    handleChoice = (item) => {
        const {activeTab} = this.state;
        //console.log(item)
        this.setState({
            isPop: false
        })
        let params = {
            id: item.siteId,
        }
        if (activeTab == 0) {
            this.setState({
                siteDate0: item
            })
        } else if (activeTab == 1) {
            this.setState({
                siteDate1: item
            })
        }
        this.siteDetailsReuest(params, activeTab)
    }
    //取消关闭弹窗
    handleCancle = () => {
        this.setState({
            isPop: false
        })
    }
    //提交数据
    handleSubmit = (params) => {
        //this.cardNumReuest(params);   2.0取消  查询用户在某站点已存在有效停车卡数量  查询近两小时内待支付和已支付的订单数量
        Modal.alert('停车说明', '请选择和您爱车匹配的收费标准充值缴费，如有疑问可先电话咨询，如车辆发生被盗事件而与缴费不匹配的，公司拒绝赔偿！', [
            { text: '取消', onPress: () => console.log('cancel') },
            {
                text: '确定',
                onPress: () =>{
                    this.createOrderReuest(params);
                }
            },
        ])
    }

    /***查询场地详情***/
    siteDetailsReuest = (params, index) => {
        Toast.loading("加载中...", 0)
        Axios.serviceRequest({
            method: 'get',
            url: '/wx/site/detail',
            params: params,
        }).then((data) => {
            // console.log(data)
            let siteDetails = data.data;
            if (index == 0) {
                this.setState({
                    siteDetails0: siteDetails
                })
            } else if (index == 1) {
                this.setState({
                    siteDetails1: siteDetails
                })
            }
        }).catch((error) => {
            console.log(error);
        })
    }
    /***根据二维码获取默认场地详情***/
    initSiteDetailsReuest = (params) => {
        Toast.loading("加载中...", 0)
        Axios.serviceRequest({
            method: 'get',
            url: '/wx/site/detail',
            params: params,
        }).then((data) => {
            //console.log(data)
            let siteDetails = data.data;
            this.setState({
                siteDetails0: siteDetails,
                siteDetails1: siteDetails
            })
        }).catch((error) => {
            console.log(error);
        })
    }
    /***创建订单***/
    createOrderReuest = (params) => {
        let _this = this;
        Toast.loading("数据提交中...", 0)
        Axios.AgtServiceRequest({
            method: 'post',
            url: '/wx/order',
            params: params,
        }).then((data) => {
            let payData = data.data;
            Toast.success("数据提交成功！", 1,)
            if (data.object=="DO_NOT_NEED_PAY") {
                //不需要支付
                window.open(`/#/agentCard`,'_self')
            }else {
                wx.ready(res => {
                    wx.checkJsApi({
                        jsApiList: ['chooseWXPay'],
                        success: res => {
                            wx.chooseWXPay({
                                ...payData,
                                success: function () {
                                    //Toast.success("支付成功！", 1,)
                                    let _params = {
                                        id:payData.orderId
                                    }
                                    _this.orderStateReuest(_params)
                                },
                                cancel:function () {
                                    let _params = {
                                        id: payData.orderId
                                    }
                                    _this.cancleOrderReuest(_params)
                                },
                                fail:function (err) {
                                    //alert(JSON.stringify(err))
                                    console.log(err)
                                }
                            })
                        },
                        fail: err => {
                            console.log(err)
                            //alert('check api fail:'+JSON.stringify(res))
                        }
                    })
                })
            }
        }).catch((error) => {
            console.log(error);
        })
    }
    /***取消订单***/
    cancleOrderReuest = (params) => {
        Toast.loading("订单取消中...", 0)
        Axios.AgtServiceRequest({
            method: 'post',
            url: '/wx/order/userCancel',
            params: params,
        }).then((data) => {
            Toast.success("订单取消成功！", 1)
        }).catch((error) => {
            console.log(error);
        })
    }
    /*** 获取订单支付状态  微信支付成功回调后执行***/
    orderStateReuest = (params) => {
        Toast.loading("获取支付结果中...", 0)
        let times = this.state.times;
        ++times;
        this.setState({times})
        Axios.serviceNoErrorRequest({
            method: 'get',
            url: '/wx/order/paidStatus',
            params: params,
        }).then((data) => {
            //console.log(data)
            if (data.data === true) {
                let siteId = sessionStorage.getItem("siteId");
                let lockId = sessionStorage.getItem("lockId");
                if (siteId&&lockId&&siteId!="null"&&lockId!="null") {
                    let _params = {
                        siteId,
                        lockId
                    }
                    this.openLockReuest(_params)
                } else {
                    window.open(`/#/agentCard`, '_self')
                }
            }else {
                if (times>3) {
                    Modal.alert('支付结果异常！', "点击确认按钮关闭页面重试！", [
                        {text: '取消', onPress: () => console.log('cancel')},
                        {
                            text: '确定',
                            onPress: () => {
                                wx.closeWindow();
                            }
                        },
                    ])
                }else {
                    setTimeout(()=>{
                        this.orderStateReuest(params);
                    },1500)
                }
            }
        }).catch((error) => {
            console.log(error);
            if (times>3) {
                Modal.alert('支付结果异常！', "点击确认按钮关闭页面重试！", [
                    {text: '取消', onPress: () => console.log('cancel')},
                    {
                        text: '确定',
                        onPress: () => {
                            wx.closeWindow();
                        }
                    },
                ])
            }else {
                Toast.loading("获取支付结果中...", 0)
                setTimeout(()=>{
                    this.orderStateReuest(params);
                },1500)
            }
        })
    }
    /***用户直接开锁***/
    openLockReuest = (params) => {
        Toast.loading("开锁中...", 0)
        Axios.serviceRequest({
            method: 'post',
            url: '/wx/parkingCard/openTheDoor',
            params: params,
        }).then((data) => {
            //开门成功
            let prePaymentType = data.data;     //付费类型
            let parkingCardId = data.object;    //停车卡id
            let status = ""
            if (prePaymentType == "NONE") {
                status = "temporaryOpenSuccess";
            } else {
                status = "repaymentOpenSuccess";
            }
            window.open(`/#/unlockingResult?parkingCardId=${parkingCardId}&status=${status}`, '_self')
        }).catch((error) => {
            console.log(error);
        })
    }
    /***链接转码-获取签名***/
    urlBase64Request = () => {
        //Toast.loading("加载中...", 0)
        let urlParams = {
            url: Base64.encode(window.location.href)
        }
        Axios.serviceRequest({
            method: 'get',
            url: '/common/getJsSdkConf',
            params: urlParams,
        }).then((data) => {
            console.log(data)
            let wxSignature = data.data;
            wx.config({
                debug: false,
                appId: wxSignature.appid,
                timestamp: wxSignature.timeStamp,
                nonceStr: wxSignature.nonceStr,
                signature: wxSignature.signature,
                jsApiList: ['chooseWXPay']
            })
            this.setState({
                wxSignature
            })
        }).catch((error) => {
            console.log(error);
        })
    }
    /***查询近两小时内待支付和已支付的订单数量***/
    orderNumReuest = (params) => {
        Toast.loading("加载中...", 0)
        Axios.AgtServiceRequest({
            method: 'get',
            url: '/wx/order/unpaidAndPaidOrderCount',
            params: {siteId: params.siteId},
        }).then((data) => {
            let num = data.data;
            if (num > 0) {
                Modal.alert('支付异常！', "存在异常订单，点击确认按钮关闭页面重试！", [
                    {text: '取消', onPress: () => console.log('cancel')},
                    {
                        text: '确定',
                        onPress: () => {
                            wx.closeWindow();
                        }
                    },
                ])
            } else {
                this.createOrderReuest(params);
            }
        }).catch((error) => {
            console.log(error);
        })
    }
    /***查询用户在某站点已存在有效停车卡数量***/
    cardNumReuest = (params) => {
        Toast.loading("加载中...", 0)
        Axios.AgtServiceRequest({
            method: 'get',
            url: '/wx/parkingCard/validCardCount',
            params: {siteId: params.siteId},
        }).then((data) => {
            let num = data.data;
            if (num > 0) {
                Modal.alert('支付异常！', "该场地已存在停车卡，请勿重复购买！点击确定退出！", [
                    {text: '取消', onPress: () => console.log('cancel')},
                    {
                        text: '确定',
                        onPress: () => {
                            wx.closeWindow();
                        }
                    },
                ])
            } else {
                this.orderNumReuest(params);
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    render() {
        const {isPop, siteDate0, siteDate1, siteDetails0, siteDetails1,activeTab } = this.state;
        return (
            <DocumentTitle title="智能车库">
                <div className="pay-box">
                    {
                        activeTab==0?
                            <Temporary
                                siteDate0={siteDate0}
                                siteDetails0={siteDetails0}
                                handleChoiceSite={this.handleChoiceSite}
                                handleSubmit={this.handleSubmit}
                            />:
                            <Recharge
                                siteDate1={siteDate1}
                                siteDetails1={siteDetails1}
                                handleChoiceSite={this.handleChoiceSite}
                                handleSubmit={this.handleSubmit}
                            />
                    }
                    {/*<Tabs*/}
                    {/*    onChange={this.handleChange}*/}
                    {/*    tabs={tabs}*/}
                    {/*    initialPage={this.state.activeTab}*/}
                    {/*    animated={false}*/}
                    {/*    useOnPan={false}*/}
                    {/*    swipeable={false}*/}
                    {/*>*/}
                    {/*    <Temporary*/}
                    {/*        siteDate0={siteDate0}*/}
                    {/*        siteDetails0={siteDetails0}*/}
                    {/*        handleChoiceSite={this.handleChoiceSite}*/}
                    {/*        handleSubmit={this.handleSubmit}*/}
                    {/*    />*/}
                    {/*    <Recharge*/}
                    {/*        siteDate1={siteDate1}*/}
                    {/*        siteDetails1={siteDetails1}*/}
                    {/*        handleChoiceSite={this.handleChoiceSite}*/}
                    {/*        handleSubmit={this.handleSubmit}*/}
                    {/*    />*/}
                    {/*</Tabs>*/}
                    {
                        isPop ?
                            <div className="pop-box">
                                <ChoiceCts
                                    handleChoice={this.handleChoice}
                                    handleCancle={this.handleCancle}
                                />
                            </div>
                            : null
                    }
                </div>
            </DocumentTitle>
        );
    }
}

//临时停车缴费
class Temporary extends React.Component {
    state = {
        vehicleImgs:[]
    }

    componentWillReceiveProps(nextProps) {
        const {siteDate0, siteDetails0} = nextProps;
        const _vehicleUnitPriceList = siteDetails0 ? siteDetails0.vehicleUnitPriceList : [];
        let vehicleUnitPriceList = [];
        //最新需求 取第一个不为0的 temporaryUnitPrice
        if (_vehicleUnitPriceList.length){
            for (let i = 0; i < _vehicleUnitPriceList.length; i++) {
                let item = _vehicleUnitPriceList[i];
                if (item.temporaryUnitPrice>0){
                    vehicleUnitPriceList.push({
                        ...item,
                        isChecked:true
                    })
                }
            }
            if (vehicleUnitPriceList.length){
                vehicleUnitPriceList.length = 1;
            }else {
                vehicleUnitPriceList.push(
                    {
                        ..._vehicleUnitPriceList[0],
                        isChecked:true
                    }
                )
            }
        }
        this.setState({
            siteDate0,
            siteDetails0,
            vehicleUnitPriceList,
            // choiceType: undefined
            choiceType: vehicleUnitPriceList[0]
        })
    }
    //普通input信息填写
    handleInputChange = (e, type) => {
        this.setState({
            [type]: e
        })
    }
    //图片上传
    handleImgChange = (val)=>{
        let vehicleImgs = val.map(item=>{
            return item.url;
        })
        this.setState({
            vehicleImgs
        })
    }
    //选择车辆类型
    handleChoiceType = (record) => {
        const {vehicleUnitPriceList} = this.state;
        vehicleUnitPriceList.map((item) => {
            if (record.id == item.id) {
                item.isChecked = true;
                this.setState({
                    choiceType: item
                })
            } else {
                item.isChecked = false;
            }
        })
        this.setState({
            vehicleUnitPriceList
        })
    }
    //临时停车确定
    handleSubmit = () => {
        const {siteDetails0, choiceType,vehicleInfo,vehicleImgs} = this.state;
        const orderType = "TEMPORARY";    //订单类型 临停缴费
        const prePaymentType = "NONE";   //付费类型 临停
        if (!siteDetails0) {
            Toast.fail("请选择场地！", 1);
            return;
        }
        if (!choiceType) {
            Toast.fail("请选择你的车辆类型！", 1);
            return;
        }
       /* if (!vehicleInfo) {
            Toast.fail("请填写车牌号或品牌型号！", 1);
            return;
        }*/
        // if (!vehicleImgs.length){
        //     Toast.fail("请至少上传一张车辆照片！", 1);
        //     return;
        // }
        const params = {
            siteId: siteDetails0.id,
            vehicleUnitPriceId: choiceType.id,
            // vehicleImgs:JSON.stringify(vehicleImgs),
            orderType,
          //  vehicleInfo,
            prePaymentType
        }
        this.props.handleSubmit(params);
        //console.log(params)
    }

    render() {
        const {siteDetails0, vehicleUnitPriceList, choiceType} = this.state;
        const temporaryUnitPrice = (choiceType ? choiceType.temporaryUnitPrice : 0).toFixed(2); //临停单价
        const temporaryCashPledge = (choiceType ? choiceType.temporaryCashPledge : 0).toFixed(2); //临停押金
        return (
            <div className="con-box">
                <div className="main">
                    <WingBlank>
                        <div className="txt-danger font-sm line-height-m" style={{padding:"5px 0"}}>请将车辆停入车库内，否则造成遗失，概不负责！</div>
                    </WingBlank>
                    <List>
                        <ListItem
                            onClick={this.props.handleChoiceSite}
                            arrow="horizontal"
                            extra={<span style={{color: "#333"}}
                            >{siteDetails0 ? siteDetails0.name : "请选择"}</span>}
                        >选择场地</ListItem>
                    </List>
                    <div className="padding-t-l" style={{paddingBottom: 5}}>
                        <WingBlank>
                            {/*<div className="tab-title">请选择你的车辆类型</div>*/}
                            <div className="tab-title">临停费用</div>
                            {
                                siteDetails0 ?
                                    <div>
                                        {
                                            vehicleUnitPriceList && vehicleUnitPriceList.length ?
                                                <ul className="tab-box">
                                                    {
                                                        vehicleUnitPriceList.map((item) => {
                                                            return (

                                                                item.isChecked ?
                                                                    <li className="active" key={item.id}
                                                                        onClick={() => this.handleChoiceType(item)}>{item.vehicleTypeName}{item.temporaryUnitPrice}元/天</li>
                                                                    :
                                                                    <li key={item.id}
                                                                        onClick={() => this.handleChoiceType(item)}>{item.vehicleTypeName}{item.temporaryUnitPrice}元/天</li>
                                                            )
                                                        })
                                                    }
                                                </ul> :
                                                <div className="txt-danger"
                                                     style={{paddingBottom: 15}}>系统暂未维护车辆类型！</div>
                                        }
                                    </div> :
                                    <div className="txt-danger" style={{padding: "15px 0"}}>请先选择场地</div>
                            }
                        </WingBlank>
                    </div>
                    {/*<List>*/}
                    {/*   <TextareaItem*/}
                    {/*        className="txt-right"*/}
                    {/*        title={<div><span className="txt-danger">*</span>车牌号/品牌型号</div>}*/}
                    {/*        placeholder="如无车牌请输入车辆品牌型号"*/}
                    {/*        autoHeight={true}*/}
                    {/*        onChange={(e) => this.handleInputChange(e, "vehicleInfo")}*/}
                    {/*    />*/}
                    {/*</List>*/}
                    {/*<WingBlank>*/}
                    {/*    <div className="tab-title" style={{padding:"10px 0 5px"}}><span className="txt-danger">*</span>车辆照片</div>*/}
                    {/*</WingBlank>*/}
                    {/*<ImgsUpload*/}
                    {/*    onChange={this.handleImgChange}*/}
                    {/*    max={3}*/}
                    {/*/>*/}
                    <div className="padding-tb-l">
                        <WingBlank>
                            <div className="item-lr padding-tb-ss">
                                <div>临停单价</div>
                                <div>{temporaryUnitPrice}/天</div>
                            </div>
                            <div className="item-lr padding-tb-ss">
                                <div>临停押金</div>
                                <div>{temporaryCashPledge}</div>
                            </div>
                            <div className="item-lr padding-tb-ss">
                                <div>实际支付</div>
                                <div style={{fontSize: 17, color: "#222"}}>{temporaryCashPledge}</div>
                            </div>
                            <div className="txt-danger" style={{paddingTop:5}}>天：以自然日计算，截止当天晚上24:00前</div>
                        </WingBlank>
                    </div>
                </div>
                <WingBlank>
                    <WhiteSpace size="xl"/>
                    <div className="txt-danger font-sm line-height-m">只需支付押金即可，临停费用从押金中扣除，出门时退还押金！</div>
                    <WhiteSpace size="lg"/>
                    <Button type="primary" onClick={this.handleSubmit}>确定</Button>
                    <WhiteSpace size="lg"/>
                </WingBlank>

            </div>
        );
    }
}

createForm()(Temporary);

//充值缴费
class Recharge extends React.Component {
    state = {
        cardCashPledge: 0,
        price: 0,
        roomNo: "",
        vehicleInfo: "",
        carports:[],
        allCarports:[],
        isNeedCard: true,
        total: 0,
        vehicleImgs:[]
    }

    componentWillMount() {
    }

    componentWillReceiveProps(nextProps) {
        const {siteDate1, siteDetails1} = nextProps;
        const vehicleUnitPriceList = siteDetails1 ? siteDetails1.vehicleUnitPriceList : [];
        console.log(siteDetails1)
        const cardCashPledge = siteDetails1 && siteDetails1.cardCashPledge ? siteDetails1.cardCashPledge : 0;//实体卡押金
        let pickerCarTypes = [];
        vehicleUnitPriceList.map((item) => {
            let typeItem = {
                label: item.vehicleTypeName,
                value: item.id,
            }
            pickerCarTypes.push(typeItem)
        })
        this.setState({
            siteDate1,
            siteDetails1,
            cardCashPledge,
            vehicleUnitPriceList,
            pickerCarTypes,
            payTypes: undefined,
            pickerTime: undefined,    //置空到期时间选择器
            choiceCarTypeData: undefined,
            prePaymentType: undefined,
        })
        if (siteDetails1){
            let enableTruckSpace = siteDetails1.enableTruckSpace; //是否激活车位
            let siteId = siteDetails1.id;
            if (enableTruckSpace==true){
                this.carportReuest({siteId});
            }
        }

    }

    //图片上传
    handleImgChange = (val)=>{
        let vehicleImgs = val.map(item=>{
            return item.url;
        })
        this.setState({
            vehicleImgs
        })
    }
    //选择车辆类型
    handleChoiceCarType = (val) => {
        const {vehicleUnitPriceList} = this.state;
        console.log(vehicleUnitPriceList) //halfYearUnitPrice
        this.setState({
            valCarType: val,
            price: 0,
            total: 0,
        })
        vehicleUnitPriceList.map((item) => {
            if (item.id == val[0]) {
                let payTypes = [
                    {
                        id: "MONTH",
                        name: `包月${item.monthUnitPrice}元`,
                        price: item.monthUnitPrice,
                        isChecked: false,
                    },
                    {
                        id: "QUARTER",
                        name: `包季${item.quarterUnitPrice}元`,
                        price: item.quarterUnitPrice,
                        isChecked: false,
                    },
                    {
                        id: "HALF_YEAR",
                        name: `包半年${item.halfYearUnitPrice}元`,
                        price: item.halfYearUnitPrice,
                        isChecked: false,
                    },
                    {
                        id: "YEAR",
                        name: `包年${item.yearUnitPrice}元`,
                        price: item.yearUnitPrice,
                        isChecked: false,
                    }
                ];
                this.setState({
                    choiceCarTypeData: item,  //选中类型后相关计费数据
                    pickerTime: undefined,    //置空到期时间选择器
                    payTypes,
                },()=>{
                    let { allCarports } = this.state;
                    let vehicleTypeId = item.vehicleTypeId;
                    let carports = allCarports.filter(item=>item.vehicleTypeId==vehicleTypeId);
                    carports = carports.map(item=>{
                        return{
                            label: item.no,
                            value: item.no,
                        }
                    });
                    if (carports.length==0){
                        let obj = {
                            label:"当前无可用车位",
                            value:""
                        }
                        carports.push(obj);
                    };
                    this.setState({
                        carports
                    })
                })
            }
        })
    }
    //套餐类型选择
    handlePayType = (record) => {
        const {payTypes} = this.state;
        payTypes.map((item) => {
            if (record.id == item.id) {
                item.isChecked = true;
                let pickerTime = [];
                let prePaymentType = "NONE";    //付费类型
                let price = item.price;
                let endTime;    //到期时间
                if (item.id == "MONTH") {
                    prePaymentType = "MONTH";
                    let months = yearMonths();
                    months.map((item, index) => {
                        let obj = {}
                        if (index == 0) {
                            obj.label = "本月底";
                            obj.value = moment(item).endOf('month').format("YYYY-MM-DD");
                            endTime = moment(item).endOf('month').format("YYYY-MM-DD");
                        } else {
                            obj.label = item;
                            obj.value = moment(item).endOf('month').format("YYYY-MM-DD");
                        }
                        pickerTime.push(obj);
                    })
                } else if (item.id == "QUARTER") {
                    prePaymentType = "QUARTER";
                    const quarterLast = moment().endOf('quarter').format("YYYY-MM-DD");     //本季度最后一天
                    let obj = {}
                    obj.label = "本季底";
                    obj.value = quarterLast;
                    endTime = quarterLast;
                    pickerTime.push(obj)
                } else if (item.id == "HALF_YEAR") {
                    prePaymentType = "HALF_YEAR";
                    let halfYearLast;
                    let currentYear = moment().format("YYYY");
                    let currentMonth = moment().format("MM");
                    if (parseInt(currentMonth)>6){
                        halfYearLast = currentYear+"-12-31";
                    }else {
                        halfYearLast = currentYear+"-06-30";
                    }
                    let obj = {}
                    obj.label = parseInt(currentMonth)>6?"下半年底":"上半年底";
                    obj.value = halfYearLast;
                    endTime = halfYearLast;
                    pickerTime.push(obj)
                }else if (item.id == "YEAR") {
                    prePaymentType = "YEAR";
                    const yearLast = moment().endOf('year').format("YYYY-MM-DD");     //本年度最后一天
                    let obj = {}
                    obj.label = "本年底";
                    obj.value = yearLast;
                    endTime = yearLast;
                    pickerTime.push(obj)
                }
                this.setState({
                    pickerTime,
                    prePaymentType,
                    endTime: [endTime],
                    price
                }, () => {
                    this.getCost();
                })
            } else {
                item.isChecked = false;
            }
        })
        this.setState({
            payTypes
        })
    }
    //选择到期时间
    handleChoiceEndTime = (val) => {
        this.setState({
            endTime: val
        }, () => {
            this.getCost();
        })
    }
    //普通input信息填写
    handleInputChange = (e, type) => {
        this.setState({
            [type]: e
        })
    }
    //是否需要实体卡
    handleIsNeedCard = (e) => {
        this.setState({
            isNeedCard: e.target.checked
        }, () => {
            this.getCost();
        })
    }
    //选择车位
    handleChoiceCarport = (e) =>{
        //console.log(e)
        this.setState({
            truckSpaceNo:e
        })
    }
    //充值确定
    handleSubmit = () => {
        const {isNeedCard, siteDetails1, choiceCarTypeData, prePaymentType, endTime, roomNo, vehicleInfo,truckSpaceNo,vehicleImgs,entityCardNo } = this.state;
        const orderType = isNeedCard ? "PREPAYMENT_AND_CARD" : "PREPAYMENT";
        if (!siteDetails1) {
            Toast.fail("请选择场地！", 1);
            return;
        }
        if (!choiceCarTypeData) {
            Toast.fail("请选择车辆类型！", 1);
            return;
        }
        if (!prePaymentType) {
            Toast.fail("请选择套餐类型！", 1);
            return;
        }
        /*if (!roomNo) {
            Toast.fail("请填写房号！", 1);
            return;
        }
        if (!vehicleInfo) {
            Toast.fail("请填写车牌号或品牌型号！", 1);
            return;
        }*/
        // if (!vehicleImgs.length){
        //     Toast.fail("请至少上传一张车辆照片！", 1);
        //     return;
        // }
        const params = {
            siteId: siteDetails1.id,
            vehicleUnitPriceId: choiceCarTypeData.id,
            endTime: endTime[0],
            // truckSpaceNo:truckSpaceNo&&truckSpaceNo.length?truckSpaceNo[0]:"",
            // vehicleImgs:JSON.stringify(vehicleImgs),
            orderType,
            prePaymentType,
            entityCardNo:isNeedCard ? entityCardNo : ''
            //roomNo,
           // vehicleInfo,
        }
        this.props.handleSubmit(params);
    }

    //计算总价
    getCost = () => {
        const {price, cardCashPledge, isNeedCard, prePaymentType, endTime} = this.state;
        let total;
        let cost = 0; //除去押金花费
        const currentDay = moment().format('YYYY-MM-DD');   //当前时间
        if (prePaymentType == "MONTH") {
            const range = moment.range(currentDay, endTime[0]);
            const monthsSE = Array.from(range.by('months'));  //到期时间包含月份，包含本月
            //当前月
            const ctMonthSurplusDays = monthSurplus(); //当前月剩余天数
            const ctMonthDays = monthDays();  //当月总天数
            if (monthsSE.length == 1) {
                cost = (ctMonthSurplusDays / ctMonthDays) * price;
            } else {
                cost = (ctMonthSurplusDays / ctMonthDays) * price + (monthsSE.length - 1) * price;
            }
        } else if (prePaymentType == "QUARTER") {
            const ctQuarterSurplus = quarterSurplus();
            const ctQuarterDays = quarterDays();
            cost = (ctQuarterSurplus / ctQuarterDays) * price;
        } else if (prePaymentType == "HALF_YEAR"){
            const ctHalfYearSsurplus = halfYearSurplus();
            const ctHalfYearDays = halfYearDays();
            cost = (ctHalfYearSsurplus / ctHalfYearDays) * price;
        } else if (prePaymentType == "YEAR") {
            const ctYearSsurplus = yearSsurplus();
            const ctYearDays = yearDays();
            cost = (ctYearSsurplus / ctYearDays) * price;
        }
        if (isNeedCard) {
            total = cost + cardCashPledge;
        } else {
            total = cost;
        }
        // console.log(total)
        this.setState({
            total
        })
    }

    /***查询有效车位***/
    carportReuest = (params) => {
        Toast.loading("加载中...", 0)
        Axios.serviceRequest({
            method: 'get',
            url: '/wx/site/leisureTruckSpaces',
            params: params,
        }).then((data) => {
            let allCarports = data.data || [];
            this.setState({
                allCarports
            })
        }).catch((error) => {
            console.log(error);
        })
    }

    render() {
        const {siteDetails1, pickerCarTypes, payTypes, pickerTime, prePaymentType, cardCashPledge, price, isNeedCard, total,carports,choiceCarTypeData } = this.state;
        return (
            <div className="con-box">
                <div className="main">
                    <List>
                        <ListItem
                            onClick={this.props.handleChoiceSite}
                            arrow="horizontal"
                            extra={<span style={{color: "#333"}}
                            >{siteDetails1 ? siteDetails1.name : "请选择"}</span>}
                        >选择场地</ListItem>
                        {
                            siteDetails1 ?
                                <Picker
                                    data={pickerCarTypes ? pickerCarTypes : []}
                                    cols={1}
                                    value={this.state.valCarType}
                                    onChange={this.handleChoiceCarType}
                                >
                                    <ListItem arrow="horizontal">车辆类型</ListItem>
                                </Picker> : null
                        }
                        {/*{*/}
                        {/*    siteDetails1&&siteDetails1.enableTruckSpace&&choiceCarTypeData ?*/}
                        {/*        <Picker*/}
                        {/*            data={carports ? carports : []}*/}
                        {/*            cols={1}*/}
                        {/*            value={this.state.truckSpaceNo}*/}
                        {/*            onChange={this.handleChoiceCarport}*/}
                        {/*        >*/}
                        {/*            <ListItem arrow="horizontal">选择车位</ListItem>*/}
                        {/*        </Picker> : null*/}
                        {/*}*/}
                    </List>
                    {
                        payTypes ?
                            <div className="padding-t-l" style={{paddingBottom: 5}}>
                                <WingBlank>
                                    <div className="tab-title">套餐类型<span className="describe">按实际剩余时长计费</span></div>
                                    {
                                        payTypes.length ?
                                            <ul className="tab-box">
                                                {
                                                    payTypes.map((item, index) => {
                                                        /*return (
                                                            item.isChecked&&item.price ?
                                                                <li className="active" key={item.id}
                                                                    onClick={() => this.handlePayType(item)}>{item.name}</li>
                                                                :
                                                                <li key={item.id}
                                                                        onClick={() => this.handlePayType(item)}>{item.name}</li>
                                                        )*/
                                                        if (item.isChecked&&item.price>0) {
                                                            return (
                                                                <li className="active" key={item.id}
                                                                    onClick={() => this.handlePayType(item)}>{item.name}</li>
                                                            )
                                                        }else if ((!item.isChecked)&&item.price>0) {
                                                            return (
                                                                <li key={item.id}
                                                                    onClick={() => this.handlePayType(item)}>{item.name}</li>
                                                            )
                                                        }
                                                    })
                                                }
                                            </ul> :
                                            <div className="txt-danger" style={{padding: "15px 0"}}>暂无套餐类型！</div>
                                    }
                                </WingBlank>
                            </div> : null
                    }
                    <List>
                        {
                            pickerTime ?
                                <Picker
                                    data={pickerTime}
                                    value={this.state.endTime}
                                    onChange={this.handleChoiceEndTime}
                                    disabled={prePaymentType == "QUARTER" || prePaymentType == "HALF_YEAR" || prePaymentType == "YEAR"}
                                    cols={1}
                                >
                                    <ListItem arrow="horizontal">到期时间</ListItem>
                                </Picker> : null
                        }
                        {/*<InputItem
                            className="txt-right"
                            type="text"
                            placeholder="请输入你的房号"
                            onChange={(e) => this.handleInputChange(e, "roomNo")}
                        ><span className="txt-danger">*</span>房号</InputItem>
                        <TextareaItem
                            className="txt-right"
                            title={<div><span className="txt-danger">*</span>车牌号/品牌型号</div>}
                            placeholder="如无车牌请输入车辆品牌型号"
                            autoHeight={true}
                            onChange={(e) => this.handleInputChange(e, "vehicleInfo")}
                        />*/}
                    </List>
                    {/*<WingBlank>*/}
                    {/*    <div className="tab-title" style={{padding:"10px 0 5px"}}><span className="txt-danger">*</span>车辆照片</div>*/}
                    {/*</WingBlank>*/}
                    {/*<ImgsUpload*/}
                    {/*    onChange={this.handleImgChange}*/}
                    {/*    max={3}*/}
                    {/*/>*/}
                    {/*<WingBlank>
                        <WhiteSpace size="lg"/>
                        <div className="txt-danger font-sm line-height-m">本车库支持微信扫码开门，可无需办理实体卡</div>
                    </WingBlank>*/}
                    <AgreeItem onChange={this.handleIsNeedCard}>
                        我需要实体卡（需缴纳{parseFloat(cardCashPledge).toFixed(2)}元押金）
                    </AgreeItem>
                    {
                        isNeedCard&&
                        <InputItem
                            className="txt-right"
                            type="text"
                            placeholder="请输入实体卡卡号"
                            onChange={(e) => this.handleInputChange(e, "entityCardNo")}
                        >实体卡卡号</InputItem>
                    }
                    <div className="txt-danger" style={{padding:"5px 15px"}}>若不需要实体卡，请取消勾选</div>
                    <div className="padding-tb-ss">
                        <WingBlank>
                            <div className="item-lr padding-tb-ss">
                                <div>套餐单价</div>
                                <div>{parseFloat(price).toFixed(2)}</div>
                            </div>
                            {
                                isNeedCard ?
                                    <div className="item-lr padding-tb-ss">
                                        <div>卡片押金</div>
                                        <div>{parseFloat(cardCashPledge).toFixed(2)}</div>
                                    </div> : null
                            }
                            <div className="item-lr padding-tb-ss">
                                <div>总计</div>
                                <div style={{fontSize: 17, color: "#222"}}>{parseFloat(total).toFixed(2)}</div>
                            </div>
                        </WingBlank>
                    </div>
                </div>
                <WingBlank>
                    <WhiteSpace size="xl"/>
                    <Button type="primary" onClick={this.handleSubmit}>确认支付</Button>
                    <WhiteSpace size="xl"/>
                </WingBlank>
            </div>
        );
    }
}

createForm()(Recharge);
