import React from 'react'
import './index.less';
import { List, InputItem,WhiteSpace,Button,Toast,WingBlank,Checkbox } from 'antd-mobile';
import { getUrlParam,FilterParam } from "../../utils/utils";
import DocumentTitle from './../../components/DocumentTitle';
import Axios from './../../axios';


export default class Standard extends React.Component {
    state = {

    }
    componentWillMount() {
        let params = {key:"measureIndemnity"}
        this.standardReuest(params)
    }

    /***获取标准***/
    standardReuest = (params) => {
        Toast.loading("加载中...", 0)
        Axios.serviceRequest({
            method: 'get',
            url: '/common/option',
            params: params,
        }).then((data) => {
            console.log(data)
            let content = data.data;
            this.setState({
                content
            })
        }).catch((error) => {
            console.log(error);
        })
    }
    render() {
        const { content } = this.state;
        return (
            <DocumentTitle title="赔偿标准">
                <div className="common-rich-text">
                    <div style={{padding:"0 15px"}}>
                        <div dangerouslySetInnerHTML={{
                            __html: content
                        }}/>
                    </div>
                </div>
            </DocumentTitle>
        );
    }
}


