import axios from 'axios'
import { Toast } from 'antd-mobile';
//const baseURL = 'http://192.168.0.123:8080';
//const baseURL = '//zdy.tt.jyapi.cn';//本地
// 正式域名
// const baseURL = 'https://zdy.jiangtour.com';
// const baseURL = '//zdy.jiangtour.com';
// 测试环境域名
// const baseURL = 'http://zdy.scdoi.cn';
const baseURL = '//zdy.scdoi.cn';

const Success_Code = 0;

export default class Axios {
  //通用请求
  static serviceRequest(options) {
    return new Promise((resolve, reject) => {
      axios({
        method: options.method,
        url: options.url,
        baseURL: baseURL,
        headers: {
          'Authorization': sessionStorage.getItem("Authorization") ? sessionStorage.getItem("Authorization") : "",
          'X-User-Id': sessionStorage.getItem("X-User-Id") ? sessionStorage.getItem("X-User-Id") : "",
        },
        data: options.method == "post" ? options.params : undefined,
        params: options.method == "get" || options.method == "put" ? options.params : undefined,
        transformRequest: [function (data) {
          let ret = ''
          for (let it in data) {
            ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
          }
          return ret
        }],
      }).then((response) => {
        if (response.status == '200') {
          let data = response.data;
          if (data.code == Success_Code) {
            Toast.hide();
            resolve(data);
          } else if (data.code == 4001) {
            Toast.hide();

            window.open('/#/register', '_self');
          }
          else {
            Toast.fail(data.msg, 2);
            reject(response.data);
          }
        } else {
          Toast.fail('服务器请求出错！', 1.5)
          reject(response.data);
        }
      }).catch((err) => {
        //console.log(err);
        Toast.fail('服务器异常！', 1.5)
        reject(err);
      });
    })
  }
  //适用于代理用户
  static AgtServiceRequest(options) {
    return new Promise((resolve, reject) => {
      axios({
        method: options.method,
        url: options.url,
        baseURL: baseURL,
        headers: {
          'Authorization': sessionStorage.getItem("Agt-Authorization") ? sessionStorage.getItem("Agt-Authorization") : "",
          'X-User-Id': sessionStorage.getItem("Agt-X-User-Id") ? sessionStorage.getItem("Agt-X-User-Id") : "",
        },
        data: options.method == "post" ? options.params : undefined,
        params: options.method == "get" || options.method == "put" ? options.params : undefined,
        transformRequest: [function (data) {
          let ret = ''
          for (let it in data) {
            ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
          }
          return ret
        }],
      }).then((response) => {
        if (response.status == '200') {
          let data = response.data;
          if (data.code == Success_Code) {
            Toast.hide();
            resolve(data);
          } else {
            Toast.fail(data.msg, 2);
            reject(response.data);
          }
        } else {
          Toast.fail('服务器请求出错！', 1.5)
          reject(response.data);
        }
      }).catch((err) => {
        //console.log(err);
        Toast.fail('服务器异常！', 1.5)
        reject(err);
      });
    })
  }
  //通用请求  没有错误信息提示
  static serviceNoErrorRequest(options) {
    return new Promise((resolve, reject) => {
      axios({
        method: options.method,
        url: options.url,
        baseURL: baseURL,
        headers: {
          'Authorization': sessionStorage.getItem("Authorization") ? sessionStorage.getItem("Authorization") : "",
          'X-User-Id': sessionStorage.getItem("X-User-Id") ? sessionStorage.getItem("X-User-Id") : "",
        },
        data: options.method == "post" ? options.params : undefined,
        params: options.method == "get" || options.method == "put" ? options.params : undefined,
        transformRequest: [function (data) {
          let ret = ''
          for (let it in data) {
            ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
          }
          return ret
        }],
      }).then((response) => {
        if (response.status == '200') {
          let data = response.data;
          if (data.code == Success_Code) {
            Toast.hide();
            resolve(data);
          } else {
            reject(response.data);
          }
        } else {
          reject(response.data);
        }
      }).catch((err) => {
        reject(err);
      });
    })
  }
  static AgtServiceNoErrorRequest(options) {
    return new Promise((resolve, reject) => {
      axios({
        method: options.method,
        url: options.url,
        baseURL: baseURL,
        headers: {
          'Authorization': sessionStorage.getItem("Agt-Authorization") ? sessionStorage.getItem("Agt-Authorization") : "",
          'X-User-Id': sessionStorage.getItem("Agt-X-User-Id") ? sessionStorage.getItem("Agt-X-User-Id") : "",
        },
        data: options.method == "post" ? options.params : undefined,
        params: options.method == "get" || options.method == "put" ? options.params : undefined,
        transformRequest: [function (data) {
          let ret = ''
          for (let it in data) {
            ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
          }
          return ret
        }],
      }).then((response) => {
        if (response.status == '200') {
          let data = response.data;
          if (data.code == Success_Code) {
            Toast.hide();
            resolve(data);
          } else {
            reject(response.data);
          }
        } else {
          reject(response.data);
        }
      }).catch((err) => {
        reject(err);
      });
    })
  }
  static jsonRequest(options) {
    return new Promise((resolve, reject) => {
      axios({
        method: options.method,
        url: options.url,
        baseURL: baseURL,
        headers: {
          'Authorization': sessionStorage.getItem("Authorization") ? sessionStorage.getItem("Authorization") : "",
          'X-User-Id': sessionStorage.getItem("X-User-Id") ? sessionStorage.getItem("X-User-Id") : "",
        },
        data: options.params,
      }).then((response) => {
        if (response.status == '200') {
          let data = response.data;
          if (data.code == Success_Code) {
            Toast.hide();
            resolve(data);
          } else {
            Toast.fail(data.msg, 2);
            reject(response.data);
          }
        } else {
          Toast.fail('服务器请求出错！', 1.5)
          reject(response.data);
        }
      }).catch((err) => {
        //console.log(err);
        Toast.fail('服务器异常！', 1.5)
        reject(err);
      });
    })
  }
}