import React from 'react'
import './index.less';
import { createForm,formShape } from 'rc-form';
import { List, InputItem,WhiteSpace,Button,Toast,WingBlank,Tabs,Picker,TextareaItem,Checkbox,Flex,SearchBar,Modal  } from 'antd-mobile';
import DocumentTitle from './../../components/DocumentTitle';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import Axios from './../../axios';
import defaultImg from "../../assets/default_img.png"
const moment = extendMoment(Moment);
const ListItem = List.Item;
const AgreeItem = Checkbox.AgreeItem;
const FlexItem = Flex.Item;

export default class RefundRecord extends React.Component {
    state = {
        records:[],
    }
    initparams = {
        max:10,
        page:1
    }
    componentWillMount() {
        this.recordReuest(this.initparams);
    }
    //加载更多
    handdleMore = ()=>{
        this.initparams = {
            ...this.initparams,
            page:this.initparams.page+1
        }
        this.recordReuest(this.initparams);
    }

    /***用户查询退款列表***/
    recordReuest = (params) => {
        Toast.loading("加载中...", 0)
        Axios.serviceRequest({
            method: 'get',
            url: '/wx/parkingCard/parkingCardRefund',
            params: params,
        }).then((data) => {
            console.log(data)
            let page = data.page;
            let pageNumber = page.pageNumber;
            let pageSize = page.pageSize;
            let totalPage = page.totalPage;
            let totalRow = page.totalRow;
            this.initparams = {
                ...this.initparams,
                page:pageNumber
            }
            let dataSource = data.data;
            let { records } = this.state;
            dataSource.map(item=>{
                records.push(item)
            })
            this.setState({
                pageNumber,
                totalPage,
                records
            })
        }).catch((error) => {
            console.log(error);
        })
    }
    render() {
        let { records,pageNumber,totalPage } = this.state;
        return (
            <DocumentTitle title="退款记录">
                <div className="my-card-box">
                    <WhiteSpace size="lg"/>
                    {
                        records&&records.length?
                            <div>
                                {
                                    records.map((item,index)=>{
                                        return(
                                            <RefRecord
                                                key={index}
                                                item={item}
                                            />
                                        )
                                    })
                                }
                            </div>:
                            <div className="no-cards" style={{marginTop:"100px"}}>暂无退款记录！</div>
                    }
                    {
                        totalPage>pageNumber?<div style={{textAlign:"center"}}><a className="txt-primary" onClick={this.handdleMore}>加载更多</a></div>:null
                    }
                    {
                        totalPage==pageNumber?<div style={{textAlign:"center"}}><a style={{color:"#999"}}>没有更多了！</a></div>:null
                    }
                </div>
            </DocumentTitle>
        );
    }
}

export class RefRecord extends React.Component {
    state = {
    }
    componentWillMount() {
    }
    //查看详情
    handleView = (item)=>{
        window.open(`/#/refundRecord/refundDetails?id=${item.id}`,'_self')
    }
    render() {
        let item = this.props.item || {};
        const prePaymentTypeCofig = {
            "NONE":"临停",
            "MONTH":"包月",
            "QUARTER":"包季",
            "HALF_YEAR":"包半年",
            "YEAR":"包年",
        }
        return (
            <WingBlank>
                <div className="refund-record-card" onClick={()=>this.handleView(item)}>
                    <div className="head">
                        <div>{item.vehicleTypeName}：{item.prePaymentType?prePaymentTypeCofig[item.prePaymentType]:""}</div>
                        <div>应退：￥{item.totalAmount}</div>
                    </div>
                    <div className="foot">
                        <div>发起时间：{item.createTime}</div>
                        <div>
                            { item.state == "INIT" ? <span className="txt-danger">待审核</span> : null }
                            { item.state == "APPROVED" ? <span className="txt-primary">审核通过</span> : null }
                            { item.state == "REFUSE" ? <span className="txt-danger">审核拒绝</span> : null }
                            { item.state == "TRANSFER_PENDING" ? <span className="txt-primary">转账中</span> : null }
                            { item.state == "TRANSFER_FAIL" ? <span className="txt-danger">转账失败</span> : null }
                            { item.state == "TRANSFER_SUCCESS" ? <span className="txt-primary">转账成功</span> : null }

                        </div>
                    </div>
                </div>
            </WingBlank>
        );
    }
}




