import React from 'react'
import './index.less';
import {List, InputItem, WhiteSpace, Button, Toast, WingBlank, Modal} from 'antd-mobile';
import { getUrlParam,FilterParam } from "../../utils/utils";
import DocumentTitle from './../../components/DocumentTitle';
import Axios from './../../axios';
import {Base64} from "js-base64";

const wx = window.wx;
const Item = List.Item;

export default class RepairCard extends React.Component {
    state = {
        times: 0,    //轮询次数
    }
    componentWillMount() {
        this.urlBase64Request();
        //从链接获取参数
        let urlparams = this.props.location.search;
        let id = getUrlParam("id",urlparams);
        this.detailsReuest({id})
        this.setState({
            parkingCardId:id
        })
    }
    //确定
    handleSubmit =()=>{
        const { parkingCardId } = this.state;
        this.repairCardReuest({parkingCardId})
    }

    /*停车卡详情*/
    detailsReuest = (params)=>{
        Toast.loading("加载中...",0)
        Axios.serviceRequest({
            method:'get',
            url:'/wx/parkingCard/detail',
            params:params,
        }).then((data)=>{
            console.log(data)
            let details = data.data;
            this.setState({
                details
            })
            if (details.siteId){
                this.siteDetailsReuest(
                    {
                        id:details.siteId
                    }
                )
            }
        }
        ).catch((error)=>{
            console.log(error);
        })
    }
    /*场地详情*/
    siteDetailsReuest = (params)=>{
        Toast.loading("加载中...",0)
        Axios.serviceRequest({
            method:'get',
            url:'/wx/site/detail',
            params:params,
        }).then((data)=>{
            console.log(data)
            let details = data.data || {};
            let cardCashPledge = details.cardCashPledge;
            this.setState({
                cardCashPledge
            })
        }).catch((error)=>{
            console.log(error);
        })
    }
    /*创建补卡订单*/
    repairCardReuest = (params)=>{
        let _this = this;
        Toast.loading("请求中...",0)
        Axios.serviceRequest({
            method:'post',
            url:'/wx/order/buka',
            params:params,
        }).then((data)=>{
            console.log(data)
            let payData = data.data;
            Toast.success("数据提交成功！", 1,)
            if (data.object == "DO_NOT_NEED_PAY") {
                //不需要支付
                window.open(`/#/myCard`, '_self')
            } else {
                wx.ready(res => {
                    wx.checkJsApi({
                        jsApiList: ['chooseWXPay'],
                        success: res => {
                            wx.chooseWXPay({
                                ...payData,
                                success: function () {
                                    //Toast.success("支付成功！", 1,)
                                    let _params = {
                                        id: payData.orderId
                                    }
                                    _this.orderStateReuest(_params)
                                },
                                cancel:function () {
                                    let _params = {
                                        id: payData.orderId
                                    }
                                    _this.cancleOrderReuest(_params)
                                },
                                fail: function (err) {
                                    console.log(err)
                                }
                            })
                        },
                        fail: err => {
                            console.log(err)
                            //alert('check api fail:'+JSON.stringify(res))
                        }
                    })
                })
            }
        }).catch((error)=>{
            console.log(error);
        })
    }
    /***链接转码-获取签名***/
    urlBase64Request = () => {
        //Toast.loading("加载中...", 0)
        let urlParams = {
            url: Base64.encode(window.location.href)
        }
        Axios.serviceRequest({
            method: 'get',
            url: '/common/getJsSdkConf',
            params: urlParams,
        }).then((data) => {
            console.log(data)
            let wxSignature = data.data;
            wx.config({
                debug: false,
                appId: wxSignature.appid,
                timestamp: wxSignature.timeStamp,
                nonceStr: wxSignature.nonceStr,
                signature: wxSignature.signature,
                jsApiList: ['chooseWXPay']
            })
            this.setState({
                wxSignature
            })
        }).catch((error) => {
            console.log(error);
        })
    }
    /***取消订单***/
    cancleOrderReuest = (params) => {
        Toast.loading("订单取消中...", 0)
        Axios.serviceRequest({
            method: 'post',
            url: '/wx/order/userCancel',
            params: params,
        }).then((data) => {
            Toast.success("订单取消成功！", 1)
        }).catch((error) => {
            console.log(error);
        })
    }
    /*** 获取订单支付状态  微信支付成功回调后执行***/
    orderStateReuest = (params) => {
        Toast.loading("获取支付结果中...", 0)
        let times = this.state.times;
        ++times;
        this.setState({times})
        Axios.serviceNoErrorRequest({
            method: 'get',
            url: '/wx/order/paidStatus',
            params: params,
        }).then((data) => {
            //console.log(data)
            if (data.data === true) {
                window.open(`/#/myCard`, '_self')
            }else {
                if (times>3) {
                    Modal.alert('支付结果异常！', "点击确认按钮关闭页面重试！", [
                        {text: '取消', onPress: () => console.log('cancel')},
                        {
                            text: '确定',
                            onPress: () => {
                                wx.closeWindow();
                            }
                        },
                    ])
                }else {
                    setTimeout(()=>{
                        this.orderStateReuest(params);
                    },1500)
                }
            }
        }).catch((error) => {
            console.log(error);
            if (times>3) {
                Modal.alert('支付结果异常！', "点击确认按钮关闭页面重试！", [
                    {text: '取消', onPress: () => console.log('cancel')},
                    {
                        text: '确定',
                        onPress: () => {
                            wx.closeWindow();
                        }
                    },
                ])
            }else {
                Toast.loading("获取支付结果中...", 0)
                setTimeout(()=>{
                    this.orderStateReuest(params);
                },1500)
            }
        })
    }
    render() {
        let details = this.state.details || {};
        const config = {
            "NONE":"临停",
            "MONTH":"包月",
            "QUARTER":"包季",
            "HALF_YEAR":"包半年",
            "YEAR":"包年",
        }
        return (
            <DocumentTitle title="补卡">
                <div className="common-item-lr">
                    <List>
                        <Item extra={details.siteName} wrap={true}>当前场地</Item>
                        <Item extra={details.prePaymentType?config[details.prePaymentType]:""} wrap={true}>当前套餐</Item>
                        <Item extra={details.vehicleTypeName} wrap={true}>车辆类型</Item>
                        <Item extra={details.vehicleInfo} wrap={true}>车牌号/品牌型号</Item>
                        <Item extra={details.endTime} wrap={true}>到期时间</Item>
                        <Item extra={<span className="txt-danger" style={{fontSize:18}}>￥{this.state.cardCashPledge}</span>} wrap={true}>实体卡押金</Item>
                    </List>
                    <WhiteSpace size="xl"/>
                    <WingBlank>
                        <div className="txt-danger" style={{marginBottom:30}}>已办实体卡用户选择补卡，则之前实体卡失效，以新办实体卡为准</div>
                        <Button type="primary" onClick={this.handleSubmit}>缴实体卡押金</Button>
                    </WingBlank>
                </div>
            </DocumentTitle>
        );
    }
}


