import React from 'react'
import './index.less';
import { createForm,formShape } from 'rc-form';
import { List, InputItem,WhiteSpace,Button,Toast,WingBlank,Tabs,Picker,TextareaItem,Checkbox,Flex,SearchBar,Modal  } from 'antd-mobile';
import DocumentTitle from './../../components/DocumentTitle';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import Axios from './../../axios';
import defaultImg from "../../assets/default_img.png"
const moment = extendMoment(Moment);
const ListItem = List.Item;
const AgreeItem = Checkbox.AgreeItem;
const FlexItem = Flex.Item;

export default class MyCar extends React.Component {
    state = {
    }
    componentWillMount() {
        this.carsReuest();
    }
    /***查询卡列表***/
    carsReuest = () => {
        Toast.loading("加载中...", 0)
        Axios.serviceRequest({
            method: 'get',
            url: '/wx/parkingCard',
            params: "",
        }).then((data) => {
            console.log(data)
            let cars = data.data;
            this.setState({
                cars
            })
        }).catch((error) => {
            console.log(error);
        })
    }
    render() {
        let { cars } = this.state;
        return (
            <DocumentTitle title="我的车辆">
                <div className="my-car-box">
                    {/*<WingBlank>*/}
                    {/*    <WhiteSpace size="lg"/>*/}
                    {/*    <Button type="ghost" onClick={()=>{window.open(`/#/pay`,'_self');}}>新增车辆</Button>*/}
                    {/*    <WhiteSpace size="lg"/>*/}
                    {/*</WingBlank>*/}
                    <WhiteSpace size="lg"/>
                    {
                        cars&&cars.length?
                            <div>
                                {
                                    cars.map((item,index)=>{
                                        return(
                                            <Car
                                                key={index}
                                                item={item}
                                            />
                                        )
                                    })
                                }
                            </div>:
                            <div className="no-cards">暂无车辆数据！</div>
                    }
                </div>
            </DocumentTitle>
        );
    }
}

export class Car extends React.Component {
    state = {
    }
    componentWillMount() {
    }
    handleChange = (item,e)=>{
        let id = item.id;
        let file = e.target.files[0];
        let formData = new FormData();
        formData.append("file", file);
        formData.append("dir", "car");
        formData.append("open", true);
        Toast.loading("上传中...", 0)
        Axios.jsonRequest({
            method:'post',
            url:'/common/singleUpload',
            params:formData,
        }).then((data)=>{
           // Toast.hide();
           // console.log(data)
            let info = data.data||{};
            let imgUrl   = info.url;
            let params = {
                id:item.id,
                imgUrl
            }
            this.uploadReuest(params);
        }).catch((error)=>{
            console.log(error);
            // Toast.hide();
        })
    }
    /***上传车辆照片***/
    uploadReuest = (params) => {
        Axios.serviceRequest({
            method: 'post',
            url: '/wx/parkingCard/uploadVehicleImg',
            params: params,
        }).then((data) => {
            //console.log(data)
            Toast.success("上传成功！", 1)
            window.location.reload()
        }).catch((error) => {
            console.log(error);
        })
    }
    render() {
        const item = this.props.item || {};
        const config = {
            "NONE":"临停",
            "MONTH":"包月",
            "QUARTER":"包季",
            "HALF_YEAR":"包半年",
            "YEAR":"包年",
        }
        const vehicleImgs = item.vehicleImgs?JSON.parse(item.vehicleImgs):[];
        return (
            <WingBlank>
                <div className="car-card">
                    {/*<div className="img-box"><img alt="icon" src={vehicleImgs[0]}/></div>*/}
                    <div className="img-box" style={{backgroundImage:`url("${vehicleImgs[0]}")`}}></div>
                    <div className="info">
                        <div className="item"><span className="item-name">{item.vehicleTypeName}：</span>{item.vehicleInfo}</div>
                        <div className="item"><span className="item-name">套餐类型：</span>{item.prePaymentType?config[item.prePaymentType]:""}</div>
                        <div className="item"><span className="item-name">场地：</span>{item.siteName}</div>
                        {/*<div className="item"><span className="item-name">车位：</span>{item.truckSpaceNo}</div>*/}
                        <a className="btn-txt-primary" style={{fontWeight:800}}>添加照片</a>
                        <input type="file" accept="image/*" capture="camera" onChange={(e)=>this.handleChange(item,e)}/>
                    </div>
                </div>
            </WingBlank>
        );
    }
}




