import React from 'react'
import './index.less';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { List, InputItem,WhiteSpace,Button,Toast,WingBlank,Tabs,Picker,TextareaItem,Checkbox,Flex,Modal  } from 'antd-mobile';
import DocumentTitle from './../../components/DocumentTitle';
import Axios from './../../axios';
import { getUrlParam } from "../../utils/utils";
import { Base64 } from 'js-base64';
const ListItem = List.Item;
const AgreeItem = Checkbox.AgreeItem;
const FlexItem = Flex.Item;
const moment = extendMoment(Moment);
const wx = window.wx;


export default class UnlockingResult extends React.Component {
    state = {
        time:0,
        resultList:[],
    }
    componentWillMount() {
        //从链接获取参数
        let urlparams = this.props.location.search;
        let parkingCardId = getUrlParam("parkingCardId",urlparams);
        let status = getUrlParam("status",urlparams);       // temporaryOpenSuccess（临停成功）  repaymentOpenSuccess  temporaryOpenFail  repaymentOpenFail
        let userId = getUrlParam("userId",urlparams);
        let token = getUrlParam("token",urlparams);
        if (userId) {
            sessionStorage.setItem("X-User-Id",userId);
        }
        if (token) {
            sessionStorage.setItem("Authorization",token);
        }

        this.setState({
            status,
        })
        let parkingCardIdsArr = parkingCardId.split(",");
        this.setState({parkingCardIdsArr})
        if (parkingCardIdsArr&&parkingCardIdsArr.length){
            let params = {
                id:parkingCardIdsArr[0]      //此id从链接获取
            }
            this.resultReuest(params);
        }

    }

    /***查询停车卡详情***/
    resultReuest = (params) => {
        Toast.loading("加载中...", 0)
        Axios.serviceRequest({
            method: 'get',
            url: '/wx/parkingCard/detail',
            params: params,
        }).then((data) => {
            //console.log(data)
            let result = data.data;
            let { resultList,time,parkingCardIdsArr } = this.state;
            time = time + 1;
            resultList.push(result)
            this.setState({
                resultList,
                time
            },()=>{
                if (time<parkingCardIdsArr.length){
                    let params = {
                        id:parkingCardIdsArr[time]      //此id从链接获取
                    }
                    this.resultReuest(params);
                }
            })
        }).catch((error) => {
            console.log(error);
        })
    }
    render() {
        let { status,result,resultList } = this.state;
        result = result?result:{};
        resultList = resultList?resultList:[];
        let temporaryDays = result.temporaryDays?result.temporaryDays:0; //天数
        let cashPledgeBalance = result.cashPledgeBalance?result.cashPledgeBalance:0; //押金剩余
        return (
            <DocumentTitle title={status=="temporaryOpenSuccess"||status=="repaymentOpenSuccess"?"开门成功":"开门失败"}>
                <div className="unlocking-result-box">
                    <Result
                        status={status}
                        result={resultList}
                    />
                    {
                        resultList.map((item,index)=>{
                            //tuikaAudit
                            if(item.tuikaAudit){
                                return null
                            }else {
                                return(
                                    <CostInfo
                                        status={status}
                                        key={index}
                                        result={item}
                                    />
                                )
                            }
                        })
                    }
                    {/*<div className="ft-btn-box"><Button type="primary" size="small">缴费</Button></div>*/}
                </div>
            </DocumentTitle>
        );
    }
}

export class Result extends React.Component {
    state = {
    }
    componentWillMount() {
    }
    componentWillReceiveProps(nextProps, nextContext) {
        let resultList = nextProps.result;
        let result = resultList&&resultList.length?resultList[0]:{};
        this.setState({
            result
        })
    }
    render() {
        const { status } = this.props;
        const { result } = this.state;
        return (
            <div className="result-box">
                <WingBlank>
                    {
                        status=="temporaryOpenSuccess"||status=="repaymentOpenSuccess"?
                            <div>
                                <div className="mark-icon"><img alt="" src="/assets/successful.png"/></div>
                                <div className="result">开门成功！</div>
                                <div className="explain">如未正常开门，请再试一次或联系工作人员</div>
                            </div>:null
                    }
                    {
                        status=="temporaryOpenFail"||status=="repaymentOpenFail"?
                            <div>
                                <div className="mark-icon"><img alt="" src="/assets/failure.png"/></div>
                                <div className="result">开门失败！</div>
                                <div className="explain">你的临停卡或套餐已过期，请及时补缴费用或续费以正常使用智定义智能车库</div>
                                {/*{
                                    status=="temporaryOpenFail"?
                                        <div className="explain">你的临停费用已超过你的押金，请补缴费用或联系工作人员</div>
                                        :
                                        <div className="explain">您的套餐已过有效期，请续费或联系工作人员</div>
                                }*/}
                            </div>:null
                    }
                    <div className="tel-box"><a href={`tell://${result&&result.contact?result.contact:''}`}>{result&&result.contact?result.contact:""}</a></div>
                </WingBlank>
            </div>
        );
    }
}

export class CostInfo extends React.Component {
    state = {
        times:0,    //轮询次数
    }
    componentWillMount() {
        this.urlBase64Request();
    }

    //退卡
    handleReturnCard = (id,type)=>{
        if (type=="NONE"){
            Modal.alert('退临时卡', '押金将会退回至你的微信账户！', [
                { text: '取消', onPress: () => console.log('cancel') },
                {
                    text: '确定',
                    onPress: () =>{
                        let params = {
                            id
                        }
                        this.returnCardReuest(params);
                    }
                },
            ])
        }
    }
    //临停补缴
    handleRestock = (item)=>{
        let orderType = "TEMPORARY_SUPPLEMENTARY";   //临停补缴
        let prePaymentType  = "NONE";   //临停补缴
        let params = {
            parkingCardId:item.id,
            siteId:item.siteId,
            orderType,
            prePaymentType,
        }
        //this.orderNumReuest(params);
        this.renewReuest(params);
    }
    //套餐续费
    handleRenew = (item)=>{
        window.open(`/#/renew?id=${item.id}&siteId=${item.siteId}&siteName=${item.siteName}`,'_self');
    }
    /***套餐过期-补缴***/
    handleFeePayable = (item)=>{
        console.log(item)
        let siteId = item.siteId;
        let vehicleTypeId  = item.vehicleTypeId;
        let prePaymentType  = item.prePaymentType;
        let parkingCardId = item.id;
        let now = moment().format("YYYY-MM-DD");
        let endTime = item.endTime ? moment(item.endTime).format("YYYY-MM-DD") : moment("2019-06-20").format("YYYY-MM-DD");   //截止时间
        let OverdueDays = moment(now).diff(moment(endTime), 'days'); //逾期天数
        Toast.loading("加载中...", 0);
        Axios.serviceRequest({
            method: 'get',
            url: '/wx/site/temporaryPrice',
            params: { siteId,vehicleTypeId },
        }).then((data) => {
            console.log(data)
            let result = data.data || {};
            let temporaryUnitPrice = result.temporaryUnitPrice ? parseFloat(result.temporaryUnitPrice) : 0; //临停单价
            let vehicleUnitPriceId = result.id;
            Modal.alert('补缴', <div>您已欠费<span className="txt-danger" >￥{temporaryUnitPrice*OverdueDays.toFixed(2)}</span>，请补缴，补缴费用之后及时退卡退费，否则系统仍将继续计费！</div>,
                [
                    { text: '取消', onPress: () => console.log('cancel') },
                    {
                        text: '确定',
                        onPress: () =>{
                            let params = {
                                count:1,
                                orderType:"PREPAYMENT_SUPPLEMENTARY",
                                prePaymentType,
                                parkingCardId,
                            }
                            this.FeePayReuest(params);
                        }
                    },
                ])

        }).catch((error) => {
            console.log(error);
        })
    };
    //是否将要到期  这里提前十天
    isWillExpire = (endTime)=>{
        let now = moment();
        endTime = moment(endTime);
        let timeDiff = endTime.diff(now,'s');   //<0  超过现在时间
        if (timeDiff<10*24*60*60) {
            return true;
        }else {
            return false;
        }
    }
    //是否逾期
    isOverdue = (endTime)=>{
        let now = moment();
        endTime = moment(endTime);
        let timeDiff = endTime.diff(now,'s');   //<0  超过现在时间
        if(timeDiff<0){
            return true;
        }else {
            return false;
        }
    }

    /*退卡请求---2.0只支持临停退卡*/
    returnCardReuest = (params)=>{
        Toast.loading("退卡中...",0)
        Axios.serviceRequest({
            method:'post',
            url:'/wx/parkingCard/tuika',
            params:params,
        }).then((data)=>{
            console.log(data)
            Toast.success("退卡成功！", 1)
            window.location.reload();
        }).catch((error)=>{
            console.log(error);
            //Toast.hide();
        })
    }
    /***订单续费***/
    renewReuest = (params) => {
        let _this = this;
        Toast.loading("数据提交中...", 0)
        Axios.serviceRequest({
            method: 'post',
            url: '/wx/order/renew',
            params: params,
        }).then((data) => {
            Toast.success("数据提交成功！", 1)
            let payData = data.data;
            if (data.object=="DO_NOT_NEED_PAY") {
                //不需要支付
                let siteId = sessionStorage.getItem("siteId");
                let lockId = sessionStorage.getItem("lockId");
                if (siteId&&lockId&&siteId!="null"&&lockId!="null") {
                    let _params = {
                        siteId,
                        lockId
                    }
                    this.openLockReuest(_params)
                }else {
                    window.open(`/#/myCard`,'_self')
                }
            }else {
                wx.ready(res => {
                    wx.checkJsApi({
                        jsApiList: ['chooseWXPay'],
                        success: res => {
                            wx.chooseWXPay({
                                ...payData,
                                success: function () {
                                    //Toast.success("支付成功！", 1,)
                                    let _params = {
                                        id:payData.orderId
                                    }
                                    _this.orderStateReuest(_params)
                                },
                                cancel:function () {
                                    let _params = {
                                        id: payData.orderId
                                    }
                                    _this.cancleOrderReuest(_params)
                                },
                                fail:function (err) {
                                    console.log(err)
                                }
                            })
                        },
                        fail: err => {
                            console.log(err)
                        }
                    })
                })
            }
            //window.open(`/pc/#/info`,'_self')
        }).catch((error) => {
            console.log(error);
        })
    }
    /***取消订单***/
    cancleOrderReuest = (params) => {
        Toast.loading("订单取消中...", 0)
        Axios.serviceRequest({
            method: 'post',
            url: '/wx/order/userCancel',
            params: params,
        }).then((data) => {
            Toast.success("订单取消成功！", 1)
        }).catch((error) => {
            console.log(error);
        })
    }
    /*** 获取订单支付状态  微信支付成功回调后执行***/
    orderStateReuest = (params) => {
        Toast.loading("获取支付结果中...", 0)
        let times = this.state.times;
        ++times;
        this.setState({times})
        Axios.serviceNoErrorRequest({
            method: 'get',
            url: '/wx/order/paidStatus',
            params: params,
        }).then((data) => {
            //console.log(data)
            if (data.data === true) {
                let siteId = sessionStorage.getItem("siteId");
                let lockId = sessionStorage.getItem("lockId");
                if (siteId&&lockId&&siteId!="null"&&lockId!="null") {
                    let _params = {
                        siteId,
                        lockId
                    }
                    this.openLockReuest(_params)
                } else {
                    window.open(`/#/myCard`, '_self')
                }
            }else {
                if (times>3) {
                    Modal.alert('支付结果异常！', "点击确认按钮关闭页面重试！", [
                        {text: '取消', onPress: () => console.log('cancel')},
                        {
                            text: '确定',
                            onPress: () => {
                                wx.closeWindow();
                            }
                        },
                    ])
                }else {
                    setTimeout(()=>{
                        this.orderStateReuest(params);
                    },1500)
                }
            }
        }).catch((error) => {
            console.log(error);
            if (times>3) {
                Modal.alert('支付结果异常！', "点击确认按钮关闭页面重试！", [
                    {text: '取消', onPress: () => console.log('cancel')},
                    {
                        text: '确定',
                        onPress: () => {
                            wx.closeWindow();
                        }
                    },
                ])
            }else {
                Toast.loading("获取支付结果中...", 0)
                setTimeout(()=>{
                    this.orderStateReuest(params);
                },1500)
            }
        })
    }
    /***用户直接开锁***/
    openLockReuest = (params) => {
        Toast.loading("开锁中...", 0)
        Axios.serviceRequest({
            method: 'post',
            url: '/wx/parkingCard/openTheDoor',
            params: params,
        }).then((data) => {
            //开门成功
            let prePaymentType = data.data;     //付费类型
            let parkingCardId = data.object;    //停车卡id
            let status = ""
            if (prePaymentType=="NONE"){
                status = "temporaryOpenSuccess";
            }else {
                status = "repaymentOpenSuccess";
            }
            window.open(`/#/unlockingResult?parkingCardId=${parkingCardId}&status=${status}`,'_self')
        }).catch((error) => {
            console.log(error);
        })
    }
    /***链接转码-获取签名***/
    urlBase64Request = ()=>{
        //Toast.loading("加载中...", 0)
        let urlParams = {
            url:Base64.encode(window.location.href)
        }
        Axios.serviceRequest({
            method: 'get',
            url: '/common/getJsSdkConf',
            params: urlParams,
        }).then((data) => {
            console.log(data)
            let wxSignature = data.data;
            wx.config({
                debug: false,
                appId: wxSignature.appid,
                timestamp: wxSignature.timeStamp,
                nonceStr: wxSignature.nonceStr,
                signature: wxSignature.signature,
                jsApiList: ['chooseWXPay']
            })
            this.setState({
                wxSignature
            })
        }).catch((error) => {
            console.log(error);
        })
    }
    /***查询近两小时内待支付和已支付的订单数量***/
    orderNumReuest = (params) => {
        Toast.loading("加载中...", 0)
        Axios.serviceRequest({
            method: 'get',
            url: '/wx/order/unpaidAndPaidOrderCount',
            params: {siteId:params.siteId},
        }).then((data) => {
            let num = data.data;
            if (num>0){
                Modal.alert('支付异常！', "存在异常订单，点击确认按钮关闭页面重试！", [
                    { text: '取消', onPress: () => console.log('cancel') },
                    {
                        text: '确定',
                        onPress: () =>{
                            wx.closeWindow();
                        }
                    },
                ])
            } else {
                this.renewReuest(params);
            }
        }).catch((error) => {
            console.log(error);
        })
    }
    /***补缴***/
    FeePayReuest = (params)=>{
        let _this = this;
        Toast.loading("数据提交中...", 0)
        Axios.serviceRequest({
            method: 'post',
            url: '/wx/order/renew',
            params: params,
        }).then((data) => {
            Toast.success("数据提交成功！", 1)
            let payData = data.data;
            if (data.object=="DO_NOT_NEED_PAY") {
                //不需要支付
                let siteId = sessionStorage.getItem("siteId");
                let lockId = sessionStorage.getItem("lockId");
                if (siteId&&lockId&&siteId!="null"&&lockId!="null") {
                    let _params = {
                        siteId,
                        lockId
                    }
                    this.openLockReuest(_params)
                }else {
                    window.open(`/#/myCard`,'_self')
                }
            }else {
                wx.ready(res => {
                    wx.checkJsApi({
                        jsApiList: ['chooseWXPay'],
                        success: res => {
                            wx.chooseWXPay({
                                ...payData,
                                success: function () {
                                    //Toast.success("支付成功！", 1,)
                                    let _params = {
                                        id:payData.orderId
                                    }
                                    _this.orderStateReuest(_params)
                                },
                                cancel:function () {
                                    let _params = {
                                        id: payData.orderId
                                    }
                                    _this.cancleOrderReuest(_params)
                                },
                                fail:function (err) {
                                    console.log(err)
                                }
                            })
                        },
                        fail: err => {
                            console.log(err)
                        }
                    })
                })
            }
        }).catch((error) => {
            console.log(error);
        })
    }
    render() {
        let { status,result } = this.props;
        result = result?result:{};
        let temporaryDays = result.temporaryDays?result.temporaryDays:0; //天数
        let temporaryUnitPrice = result.temporaryUnitPrice?result.temporaryUnitPrice:0; //单价
        let cashPledge = result.cashPledge?result.cashPledge:0; //押金
        let cashPledgeBalance = result.cashPledgeBalance?result.cashPledgeBalance:0; //剩余
        let supplementary = result.supplementary; //是否已补缴，已补缴就不能续费
        let cost = temporaryDays*temporaryUnitPrice;       //停车花费
        let supplyCost = cost - cashPledge;      //应补缴
        let prePaymentType = result.prePaymentType;
        let endTime = result.endTime||"2019-06-20";   //截止时间
        let expireSurplus;  //到期剩余天数
        let isWillExpire = this.isWillExpire(endTime);
        let isOverdue = this.isOverdue(endTime);    //套餐是否过期
        return (
            <div className="cost-info">
                {
                    prePaymentType=="NONE"?
                        <WingBlank>
                            <div className="item"><span className="item-name">卡号：</span><span className="item-details">{result.id}</span></div>
                            <div className="item"><span className="item-name">姓名：</span><span className="item-details">{result.userName}</span></div>
                            <div className="item"><span className="item-name">开门时间：</span><span className="item-details">{moment().format("YYYY-MM-DD HH:mm:ss")}</span></div>
                            <div className="item"><span className="item-name">天数：</span><span className="item-details">{temporaryDays}天</span></div>
                            <Flex>
                                <FlexItem>
                                    <div className="item"><span className="item-name">单价：</span><span className="item-details">￥{temporaryUnitPrice.toFixed(2)}</span></div>
                                </FlexItem>
                                <FlexItem>
                                    <div className="item"><span className="item-name">应付：</span><span className="item-details">￥{cost.toFixed(2)}</span></div>
                                </FlexItem>
                            </Flex>
                            <Flex>
                                <FlexItem>
                                    <div className="item"><span className="item-name">押金：</span><span className="item-details">￥{cashPledge.toFixed(2)}</span></div>
                                </FlexItem>
                                <FlexItem>
                                    <div className="item"><span className="item-name">剩余：</span><span className="item-details">￥{cashPledgeBalance.toFixed(2)}</span></div>
                                </FlexItem>
                            </Flex>
                            <div className="explain padding-b-s">如果你无需再次使用该车库，请退临时卡</div>
                            {
                                supplyCost>0?
                                    <div>
                                        <Flex>
                                            <FlexItem>
                                                <div className="item"><span className="item-name">应补缴：</span><span className="item-details">￥{supplyCost.toFixed(2)}</span></div>
                                            </FlexItem>
                                        </Flex>
                                        <div className="ft-btn-box"><Button type="primary" size="small" onClick={()=>this.handleRestock(result)}>缴费</Button></div>
                                    </div>:null
                            }
                            {
                                supplyCost<0?
                                    <div className="ft-btn-box"><Button style={{width:120}} type="primary" size="small" onClick={()=>this.handleReturnCard(result.id,prePaymentType)}>退临时卡</Button></div>:null
                            }
                        </WingBlank>
                        :
                        <WingBlank>
                            <div className="item"><span className="item-name">卡号：</span><span className="item-details">{result.id}</span></div>
                            <div className="item"><span className="item-name">车辆：</span><span className="item-details">{result.vehicleTypeName} / {result.vehicleInfo}</span></div>
                            <div className="item"><span className="item-name">姓名：</span><span className="item-details">{result.userName}</span></div>
                            <div className="item"><span className="item-name">开门时间：</span><span className="item-details">{moment().format("YYYY-MM-DD HH:mm:ss")}</span></div>
                            <div className="item"><span className="item-name">套餐类型：</span><span className="item-details">
                                {prePaymentType=="MONTH"?"包月":null}
                                {prePaymentType=="QUARTER"?"包季":null}
                                {prePaymentType=="HALF_YEAR"?"包半年":null}
                                {prePaymentType=="YEAR"?"包年":null}
                            </span></div>
                            <div className="item"><span className="item-name">截止有效期：</span><span className="item-details">{result.endTime}</span></div>
                           {/* { isWillExpire?<div className="ft-btn-box"><Button type="primary" size="small" onClick={()=>{this.handleRenew(result)}}>续费</Button></div>:null }*/}
                            <div className="ft-btn-box">
                                {
                                    supplementary!==true&&<Button style={{width:120}} type="primary" size="small" onClick={()=>{this.handleRenew(result)}}>续费</Button>
                                }
                                {
                                    isOverdue&&<Button style={{width:120}} type="primary" size="small" onClick={()=>{this.handleFeePayable(result)}}>补缴</Button>
                                }
                            </div>
                        </WingBlank>
                }
                {/*{
                    status=="temporaryOpenSuccess"||status=="temporaryOpenFail"?
                        <WingBlank>
                            <div className="explain padding-b-s">如果你无需再次使用该车库，请退临时卡</div>
                            <div className="item"><span className="item-name">卡号：</span><span className="item-details">{result.id}</span></div>
                            <div className="item"><span className="item-name">姓名：</span><span className="item-details">{result.userName}</span></div>
                            <div className="item"><span className="item-name">开门时间：</span><span className="item-details">{moment().format("YYYY-MM-DD HH:mm:ss")}</span></div>
                            <div className="item"><span className="item-name">天数：</span><span className="item-details">{temporaryDays}天</span></div>
                            <Flex>
                                <FlexItem>
                                    <div className="item"><span className="item-name">单价：</span><span className="item-details">￥{temporaryUnitPrice.toFixed(2)}</span></div>
                                </FlexItem>
                                <FlexItem>
                                    <div className="item"><span className="item-name">应付：</span><span className="item-details">￥{cost.toFixed(2)}</span></div>
                                </FlexItem>
                            </Flex>
                            <Flex>
                                <FlexItem>
                                    <div className="item"><span className="item-name">押金：</span><span className="item-details">￥{cashPledge.toFixed(2)}</span></div>
                                </FlexItem>
                                <FlexItem>
                                    <div className="item"><span className="item-name">剩余：</span><span className="item-details">￥{cashPledgeBalance.toFixed(2)}</span></div>
                                </FlexItem>
                            </Flex>
                            {
                                status=="temporaryOpenFail"&&supplyCost>0?
                                    <div>
                                        <Flex>
                                            <FlexItem>
                                                <div className="item"><span className="item-name">应补缴：</span><span className="item-details">￥{supplyCost.toFixed(2)}</span></div>
                                            </FlexItem>
                                        </Flex>
                                        <div className="ft-btn-box"><Button type="primary" size="small" onClick={()=>this.handleRestock(result)}>缴费</Button></div>
                                    </div>:null
                            }
                            {
                                status=="temporaryOpenSuccess"&&supplyCost<0?
                                    <div className="ft-btn-box"><Button type="primary" size="small" onClick={()=>this.handleReturnCard(result.id,prePaymentType)}>退卡</Button></div>:null
                            }
                        </WingBlank>
                        :null
                }
                {
                    status=="repaymentOpenSuccess"||status=="repaymentOpenFail"?
                        <WingBlank>
                            <div className="item"><span className="item-name">卡号：</span><span className="item-details">{result.id}</span></div>
                            <div className="item"><span className="item-name">姓名：</span><span className="item-details">{result.userName}</span></div>
                            <div className="item"><span className="item-name">开门时间：</span><span className="item-details">{moment().format("YYYY-MM-DD HH:mm:ss")}</span></div>
                            <div className="item"><span className="item-name">套餐类型：</span><span className="item-details">
                                {prePaymentType=="MONTH"?"包月":null}
                                {prePaymentType=="QUARTER"?"包季":null}
                                {prePaymentType=="YEAR"?"包年":null}
                            </span></div>
                            <div className="item"><span className="item-name">截止有效期：</span><span className="item-details">{result.endTime}</span></div>
                            { isWillExpire?<div className="ft-btn-box"><Button type="primary" size="small" onClick={()=>{this.handleRenew(result)}}>续费</Button></div>:null }
                        </WingBlank>:null
                }*/}
            </div>
        );
    }
}


