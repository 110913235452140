import React from 'react'
import './index.less';
import { List, InputItem, WhiteSpace, Button, Toast, WingBlank } from 'antd-mobile';
import DocumentTitle from './../../components/DocumentTitle';
import Axios from './../../axios';
import { FilterParam } from "../../utils/utils";


export default class AgentLogin extends React.Component {
    state = {
        initTxt: "获取验证码",
        wait: 0,
        registry: false,          //是否初次代理注册，代理注册必须带上真实姓名与身份证号码
    }
    componentWillMount() {
    }

    //各搜索框值
    handleChange = (e, type) => {
        this.setState({
            [type]: e
        })
        if (type == "mobile") {
            if (/^1(3|4|5|6|7|8|9)\d{9}$/.test(e)) {
                let params = {
                    mobile: e
                }
                this.verifyMobileReuest(params);
            }
        }
    }
    //确定
    handleSubmit = () => {
        const { mobile, verifyCode, realname, idcard, registry } = this.state;
        if (!mobile) {
            Toast.fail("请输入代理用户手机号码！", 1);
            return false;
        }
        if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(mobile)) {
            Toast.fail("手机号码不合法！", 1);
            return false;
        }
        if (!verifyCode) {
            Toast.fail("请输入验证码！", 1);
            return false;
        }
        if (registry) {
            if (!realname) {
                Toast.fail("请输入代理用户的真实姓名！", 1);
                return false;
            }
            /* if (!idcard){
                 Toast.fail("请输入代理用户的身份证号码！",1);
                 return false;
             }
             const idCardRegex = /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx])|([1−9]\d5\d2((0[1−9])|(10|11|12))(([0−2][1−9])|10|20|30|31)\d2[0−9Xx])|([1−9]\d5\d2((0[1−9])|(10|11|12))(([0−2][1−9])|10|20|30|31)\d2[0−9Xx])/;
             if(!idCardRegex.test(idcard)){
                 Toast.fail("身份证号码不合法！",1);
                 return false;
             }*/
        }
        let params = {
            mobile, verifyCode, realname, registry
        }
        params = FilterParam(params);
        this.agencyLoginReuest(params);
    }
    //获取验证码
    handleGetCode = () => {
        const { mobile } = this.state;
        if (!mobile) {
            Toast.fail("请输入代理用户手机号码！", 1);
            return false;
        }
        if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(mobile)) {
            Toast.fail("手机号码不合法！", 1);
            return false;
        }
        let params = {
            type: "AGENCY_IDENTITY_AUTHENTICATION",
            mobile,
        }
        this.getCodeReuest(params);
        this.setState({
            wait: 60
        }, () => {
            this.countdown();
        })
    }
    //倒计时
    countdown = () => {
        let { wait } = this.state;
        if (wait > 0) {
            wait--;
            this.setState({
                wait
            })
            setTimeout(this.countdown, 1000);
        }
    }
    /*获取验证码请求*/
    getCodeReuest = (params) => {
        Toast.loading("获取验证码中...", 0)
        Axios.serviceRequest({
            method: 'post',
            url: '/common/verifyCode',
            params: params,
        }).then((data) => {
            console.log(data)
            Toast.success("验证码获取成功,请注意查收！", 1,)
        }).catch((error) => {
            console.log(error);
            // Toast.hide();
        })
    }
    /*代理登录请求*/
    agencyLoginReuest = (params) => {
        Toast.loading("登录中...", 0)
        Axios.serviceRequest({
            method: 'post',
            url: '/wx/user/agency',
            params: params,
        }).then((data) => {
            console.log(data)
            Toast.success("登录成功！", 1)
            sessionStorage.setItem("Agt-Authorization", data.object);
            sessionStorage.setItem("Agt-X-User-Id", data.data);
            window.open(`/#/agentHome`, '_self')
        }).catch((error) => {
            console.log(error);
        })
    }
    /*验证手机号码是否注册*/
    verifyMobileReuest = (params) => {
        Toast.loading("校验中...", 0)
        Axios.serviceRequest({
            method: 'get',
            url: '/wx/user/verifyMobileRegistryStatus',
            params: params,
        }).then((data) => {
            console.log(data)
            let isNewUser = data.data;  //是否已注册
            let registry = !isNewUser;   //是否初次代理注册，代理注册必须带上真实姓名与身份证号码
            this.setState({
                registry
            })
        }).catch((error) => {
            console.log(error);
            // Toast.hide();
        })
    }
    render() {
        const { wait, registry } = this.state;
        return (
            <DocumentTitle title="代理登录">
                <div className="agent-login-box">
                    <List>
                        <InputItem
                            extra={wait > 0 ? <a className="btn-txt-disabled">{wait}s后重新发送</a> : <a className="btn-txt-primary" onClick={this.handleGetCode}>获取验证码</a>}
                            type="number"
                            placeholder="请输入代理用户手机"
                            onChange={(e) => this.handleChange(e, "mobile")}
                        >手机号码</InputItem>

                        <InputItem
                            type="text"
                            placeholder="请输入验证码"
                            onChange={(e) => this.handleChange(e, "verifyCode")}
                        >验证码</InputItem>
                        {
                            registry ?
                                <div>
                                    <InputItem
                                        type="text"
                                        placeholder="请输入代理用户的真实姓名"
                                        onChange={(e) => this.handleChange(e, "realname")}
                                    >姓名</InputItem>
                                    {/* <InputItem
                                        type="text"
                                        placeholder="请输入代理用户的身份证号码"
                                        onChange={(e)=>this.handleChange(e,"idcard")}
                                    >身份证号</InputItem>*/}
                                </div> : null
                        }
                    </List>
                    <WhiteSpace size="xl" />
                    <WingBlank>
                        <Button type="primary" onClick={this.handleSubmit}>确定</Button>
                    </WingBlank>
                </div>
            </DocumentTitle>
        );
    }
}

