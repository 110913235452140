import React from 'react'
import './index.less';
import { List, InputItem,WhiteSpace,Button,Toast,WingBlank,Tabs,Picker,TextareaItem,Checkbox,Flex,SearchBar  } from 'antd-mobile';
import DocumentTitle from './../../components/DocumentTitle';
import Axios from './../../axios';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import {yearMonths, periodMonths, getUrlParam} from "../../utils/utils";
import { Base64 } from 'js-base64';
import {Modal} from "antd-mobile/lib/index";
const ListItem = List.Item;
const AgreeItem = Checkbox.AgreeItem;
const FlexItem = Flex.Item;
const moment = extendMoment(Moment);
const wx = window.wx;


export default class AgentRenew extends React.Component {
    state = {
        price:0,
        cost:0,
        times:0,    //轮询次数
    }
    componentWillMount() {
        this.urlBase64Request();
        const urlparams = this.props.location.search;
        const id = getUrlParam("id",urlparams);
        let params = {
            id,      //此id从链接获取
        }
        this.resultReuest(params);
    }
    //选择套餐类型
    handlePayType = (record)=>{
        let { payTypes,endTime } = this.state;
        //let isOverdue = moment(endTime).diff(moment()); //是否逾期续费 判断是否超期续费 如果小于0  表示超期续费， 否则提前续费
        payTypes.map((item)=>{
            if (record.id==item.id){
                item.isChecked = true;
                let pickerTime = [];
                let prePaymentType = "NONE";    //付费类型
                let price = item.price;
                if (item.id=="MONTH") {
                    prePaymentType = "MONTH";
                    let months = periodMonths(endTime);
                    months.map((item,index)=>{
                        let obj = {}
                        obj.label = item;
                        obj.value = index+1;
                        pickerTime.push(obj);
                    })
                }else if (item.id=="QUARTER"){
                    prePaymentType = "QUARTER";
                    let obj = {}
                    obj.label = moment(endTime).add(3, 'months').format("YYYY-MM-DD");
                    obj.value = 1;
                    pickerTime.push(obj)
                }else if(item.id=="HALF_YEAR"){
                    prePaymentType = "HALF_YEAR";
                    let obj = {}
                    obj.label = moment(endTime).add(6, 'months').format("YYYY-MM-DD");
                    obj.value = 1;
                    pickerTime.push(obj)
                }else if (item.id=="YEAR"){
                    prePaymentType = "YEAR";
                    let obj = {}
                    obj.label = moment(endTime).add(1, 'year').format("YYYY-MM-DD");
                    obj.value = 1;
                    pickerTime.push(obj)
                }
                this.setState({
                    pickerTime,
                    prePaymentType,
                    count:[1],
                    price
                },()=>{
                    this.getCost();
                })
            } else {
                item.isChecked = false;
            }
        })
    }
    //选择到期时间
    handleChoiceEndTime = (val)=>{
        this.setState({
            count:val
        },()=>{
            this.getCost();
        })
    }
    //确认支付
    handleSubmit = ()=>{
        const { prePaymentType,count,siteId } = this.state;
        const urlparams = this.props.location.search;
        const orderType = "PREPAYMENT_RENEW";
        const parkingCardId = getUrlParam("id",urlparams);
        if (!prePaymentType) {
            Toast.fail("请选择套餐类型！",1);
            return false;
        }
        let params = {
            orderType,
            parkingCardId,
            prePaymentType,
            count,
            siteId
        }
        //this.orderNumReuest(params);
        this.renewReuest(params);
    }
    //计算总价
    getCost = ()=>{
        let { price,count } = this.state;
        let cost = price*count[0];
        this.setState({
            cost
        })
    }

    /***查询停车卡详情***/
    resultReuest = (params) => {
        Toast.loading("加载中...", 0)
        Axios.AgtServiceRequest({
            method: 'get',
            url: '/wx/parkingCard/detail',
            params: params,
        }).then((data) => {
            //console.log(data)
            let result = data.data;
            let siteId = result.siteId;
            let siteName = result.siteName;
            let vehicleTypeId = result.vehicleTypeId;       //车辆类型编号
            let vehicleTypeName = result.vehicleTypeName;   //车辆类型名称
            let endTime = result.endTime;   //截止时间
            this.setState({
                result,
                siteId,
                siteName,
                vehicleTypeId,
                vehicleTypeName,
                endTime,
            },()=>{
                let _params = {
                    id:siteId
                }
                this.siteDetailsReuest(_params)
            })
        }).catch((error) => {
            console.log(error);
        })
    }
    /***查询场地详情***/
    siteDetailsReuest = (params) => {
        Toast.loading("加载中...", 0)
        Axios.serviceRequest({
            method: 'get',
            url: '/wx/site/detail',
            params: params,
        }).then((data) => {
            //console.log(data)
            let siteDetails = data.data;
            let { vehicleTypeId } = this.state;
            let vehicleUnitPriceList = siteDetails.vehicleUnitPriceList||[];
            vehicleUnitPriceList.map((item)=>{
                if (item.vehicleTypeId==vehicleTypeId) {
                    let payTypes = [
                        {
                            id:"MONTH",
                            name:`包月${item.monthUnitPrice}元/月`,
                            price:item.monthUnitPrice,
                            isChecked:false,
                        },
                        {
                            id:"QUARTER",
                            name:`包季${item.quarterUnitPrice}元`,
                            price:item.quarterUnitPrice,
                            isChecked:false,
                        },
                        {
                            id: "HALF_YEAR",
                            name: `包半年${item.halfYearUnitPrice}元`,
                            price: item.halfYearUnitPrice,
                            isChecked: false,
                        },
                        {
                            id:"YEAR",
                            name:`包年${item.yearUnitPrice}元`,
                            price:item.yearUnitPrice,
                            isChecked:false,
                        }
                    ];
                    this.setState({
                        payTypes
                    })
                }
            })
        }).catch((error) => {
            console.log(error);
        })
    }
    /***订单续费***/
    renewReuest = (params) => {
        let _this = this;
        Toast.loading("数据提交中...", 0)
        Axios.AgtServiceRequest({
            method: 'post',
            url: '/wx/order/renew',
            params: params,
        }).then((data) => {
            Toast.success("数据提交成功！", 1,)
            let payData = data.data;
            if (data.object=="DO_NOT_NEED_PAY") {
                //不需要支付
                window.open(`/#/agentCard`,'_self')
            }else {
                wx.ready(res => {
                    wx.checkJsApi({
                        jsApiList: ['chooseWXPay'],
                        success: res => {
                            wx.chooseWXPay({
                                ...payData,
                                success: function () {
                                    //Toast.success("支付成功！", 1,)
                                    let _params = {
                                        id:payData.orderId
                                    }
                                    _this.orderStateReuest(_params)
                                },
                                cancel:function () {
                                    let _params = {
                                        id: payData.orderId
                                    }
                                    _this.cancleOrderReuest(_params)
                                },
                                fail:function (err) {
                                    console.log(err)
                                }
                            })
                        },
                        fail: err => {
                            console.log(err)
                        }
                    })
                })
            }
            //window.open(`/pc/#/info`,'_self')
        }).catch((error) => {
            console.log(error);
        })
    }
    /***取消订单***/
    cancleOrderReuest = (params) => {
        Toast.loading("订单取消中...", 0)
        Axios.AgtServiceRequest({
            method: 'post',
            url: '/wx/order/userCancel',
            params: params,
        }).then((data) => {
            Toast.success("订单取消成功！", 1)
        }).catch((error) => {
            console.log(error);
        })
    }
    /*** 获取订单支付状态  微信支付成功回调后执行***/
    orderStateReuest = (params) => {
        Toast.loading("获取支付结果中...", 0)
        let times = this.state.times;
        ++times;
        this.setState({times})
        Axios.serviceNoErrorRequest({
            method: 'get',
            url: '/wx/order/paidStatus',
            params: params,
        }).then((data) => {
            //console.log(data)
            if (data.data === true) {
                window.open(`/#/agentCard`, '_self')
            }else {
                if (times>3) {
                    Modal.alert('支付结果异常！', "点击确认按钮关闭页面重试！", [
                        {text: '取消', onPress: () => console.log('cancel')},
                        {
                            text: '确定',
                            onPress: () => {
                                wx.closeWindow();
                            }
                        },
                    ])
                }else {
                    setTimeout(()=>{
                        this.orderStateReuest(params);
                    },1500)
                }
            }
        }).catch((error) => {
            console.log(error);
            if (times>3) {
                Modal.alert('支付结果异常！', "点击确认按钮关闭页面重试！", [
                    {text: '取消', onPress: () => console.log('cancel')},
                    {
                        text: '确定',
                        onPress: () => {
                            wx.closeWindow();
                        }
                    },
                ])
            }else {
                Toast.loading("获取支付结果中...", 0)
                setTimeout(()=>{
                    this.orderStateReuest(params);
                },1500)
            }
        })
    }
    /***链接转码-获取签名***/
    urlBase64Request = ()=>{
        //Toast.loading("加载中...", 0)
        let urlParams = {
            url:Base64.encode(window.location.href)
        }
        Axios.serviceRequest({
            method: 'get',
            url: '/common/getJsSdkConf',
            params: urlParams,
        }).then((data) => {
            console.log(data)
            let wxSignature = data.data;
            wx.config({
                debug: false,
                appId: wxSignature.appid,
                timestamp: wxSignature.timeStamp,
                nonceStr: wxSignature.nonceStr,
                signature: wxSignature.signature,
                jsApiList: ['chooseWXPay']
            })
            this.setState({
                wxSignature
            })
        }).catch((error) => {
            console.log(error);
        })
    }
    /***查询近两小时内待支付和已支付的订单数量***/
    orderNumReuest = (params) => {
        Toast.loading("加载中...", 0)
        Axios.AgtServiceRequest({
            method: 'get',
            url: '/wx/order/unpaidAndPaidOrderCount',
            params: {siteId:params.siteId},
        }).then((data) => {
            let num = data.data;
            if (num>0){
                Modal.alert('支付异常！', "存在异常订单，点击确认按钮关闭页面重试！", [
                    { text: '取消', onPress: () => console.log('cancel') },
                    {
                        text: '确定',
                        onPress: () =>{
                            wx.closeWindow();
                        }
                    },
                ])
            } else {
                this.renewReuest(params);
            }
        }).catch((error) => {
            console.log(error);
        })
    }
    render() {
        const { siteName,vehicleTypeName,payTypes,prePaymentType,pickerTime,cost } = this.state;
        return (
            <DocumentTitle title="套餐续费">
                <div className="renew-box">
                    <div className="content">
                        <div className="item-lr padding-tb-l padding-lr border-btm">
                            <div>选择场地</div>
                            <div>{siteName}</div>
                        </div>
                        <div className="item-lr padding-tb-l padding-lr border-btm">
                            <div>车辆类型</div>
                            <div>{vehicleTypeName}</div>
                        </div>
                        {
                            payTypes?
                                <div className="padding-t-l" style={{paddingBottom:5}}>
                                    <WingBlank>
                                        <div className="tab-title">套餐类型<span className="describe">按实际续费时长计费</span></div>
                                        {
                                            payTypes.length?
                                                <ul className="tab-box">
                                                    {
                                                        payTypes.map((item,index)=>{
                                                            /*return (
                                                                item.isChecked?
                                                                    <li className="active" key={item.id} onClick={()=>this.handlePayType(item)}>{item.name}</li>
                                                                    :
                                                                    <li key={item.id} onClick={()=>this.handlePayType(item)}>{item.name}</li>
                                                            )*/
                                                            if (item.isChecked&&item.price>0) {
                                                                return (
                                                                    <li className="active" key={item.id}
                                                                        onClick={() => this.handlePayType(item)}>{item.name}</li>
                                                                )
                                                            }else if ((!item.isChecked)&&item.price>0) {
                                                                return (
                                                                    <li key={item.id}
                                                                        onClick={() => this.handlePayType(item)}>{item.name}</li>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </ul>:
                                                <div className="txt-danger" style={{padding:"15px 0"}}>暂无套餐类型！</div>
                                        }
                                    </WingBlank>
                                </div> :null
                        }

                        {
                            pickerTime?
                                <List>
                                    <Picker
                                        data={pickerTime}
                                        cols={1}
                                        value={this.state.count}
                                        onChange={this.handleChoiceEndTime}
                                        disabled={prePaymentType=="QUARTER"||prePaymentType=="HALF_YEAR"||prePaymentType=="YEAR"}
                                    >
                                        <ListItem arrow="horizontal">到期时间</ListItem>
                                    </Picker>
                                </List>:null
                        }
                        <div className="padding-tb-ss">
                            <WingBlank>
                                <div className="item-lr padding-tb-ss">
                                    <div>套餐价格</div>
                                    <div>{ parseFloat(cost).toFixed(2) }</div>
                                </div>
                                {/*<div className="item-lr padding-tb-ss">
                                    <div>总计</div>
                                    <div style={{fontSize:17,color:"#222"}}>120.00</div>
                                </div>*/}
                            </WingBlank>
                        </div>
                    </div>
                    <WingBlank>
                        <WhiteSpace size="xl"/>
                        <Button type="primary" onClick={this.handleSubmit}>确认支付</Button>
                        <WhiteSpace size="xl"/>
                    </WingBlank>
                </div>
            </DocumentTitle>
        );
    }
}