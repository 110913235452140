import React from 'react'
import { NavLink } from 'react-router-dom';
import './index.less';
import { List, InputItem,WhiteSpace,Button,Toast,WingBlank,Checkbox } from 'antd-mobile';
import { getUrlParam,FilterParam,getBase64 } from "../../utils/utils";
import DocumentTitle from './../../components/DocumentTitle';
import Axios from "../../axios";
const ListItem = List.Item;

export default class Index extends React.Component {
    state = {
        userInfo:{}
    }
    componentWillMount(){
        this.userInfoRequest();
    }
    /***获取用户信息***/
    userInfoRequest = () => {
        //Toast.loading("加载中...", 0)
        Axios.AgtServiceRequest({
            method: 'get',
            url: '/wx/user/detail',
            params: "",
        }).then((data) => {
            //console.log(data)
            let userInfo = data.data;
            this.setState({
                userInfo
            })
        }).catch((error) => {
            console.log(error);
        })
    }
    render() {
        const { userInfo } = this.state;
        return (
            <DocumentTitle title="代理账号">
               <div className="account-info">
                   <ListItem
                       extra={<span style={{color: "#666"}}>{userInfo.mobile}</span>}
                   >手机号码</ListItem>
                   <ListItem
                       extra={<span style={{color: "#666"}}>{userInfo.username ? userInfo.username : `用户${userInfo.mobile}`}</span>}
                   >姓名</ListItem>
                   <ListItem
                       arrow="horizontal"
                       onClick={()=>{ window.open('/#/agentCenter/avatar','_self');}}
                   >个人照片</ListItem>
                   {/*<ListItem*/}
                   {/*    arrow="horizontal"*/}
                   {/*    extra={<img style={{width:50,height:50,borderRadius:50}} alt="" src="http://explorer.jydev.cn/data/User/admin/home/zfr/imgs/banner_bg%402x.png"/>}*/}
                   {/*>个人照片</ListItem>*/}
                   {/*<ListItem*/}
                   {/*    arrow="horizontal"*/}
                   {/*    onClick={()=>{ window.open('/#/myCar','_self');}}*/}
                   {/*>我的车辆</ListItem>*/}
               </div>
            </DocumentTitle>
        );
    }
}


