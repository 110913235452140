import React from 'react'
import { NavLink } from 'react-router-dom';
import './index.less';
import DocumentTitle from './../../components/DocumentTitle';
import { Button, Toast } from "antd-mobile";
import { Base64 } from "js-base64";
import Axios from "../../axios";
const wx = window.wx;



export default class Index extends React.Component {
  state = {
    avatar: {}
  }
  componentWillMount() {
    console.log(window.location.href.split('#')[0])
    this.urlBase64Request();
  }
  //扫码
  handleScanQRCode = () => {
    wx.ready(res => {
      wx.scanQRCode({
        needResult: 0, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
        scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
        success: function (res) {
          var result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
        }
      });
    })
  }
  /***链接转码-获取签名***/
  urlBase64Request = () => {
    Toast.loading("加载中...", 0)
    let urlParams = {
      // url: Base64.encode(window.location.href)
      url: Base64.encode(window.location.href.split('#')[0])
    }
    Axios.serviceRequest({
      method: 'get',
      url: '/common/getJsSdkConf',
      params: urlParams,
    }).then((data) => {
      console.log(data)
      let wxSignature = data.data;
      wx.config({
        debug: false,
        appId: wxSignature.appid,
        timestamp: wxSignature.timeStamp,
        nonceStr: wxSignature.nonceStr,
        signature: wxSignature.signature,
        jsApiList: ['chooseWXPay', 'scanQRCode']
      })
      this.setState({
        wxSignature
      })
    }).catch((error) => {
      console.log(error);
    })
  }
  render() {
    return (
      <DocumentTitle title="扫码充电">
        <div className="charge-con">
          <div className="info-box">
            <div className="icon"><img alt="" src="/assets/icon_second.png" /></div>
            <p className="tips">扫描充电桩二维码</p>
            <p className="step">第二步</p>
          </div>
          <div className="btn-box">
            <Button type="primary" onClick={this.handleScanQRCode}>下一步</Button>
          </div>
        </div>
      </DocumentTitle>
    );
  }
}


