import React from 'react';
/*import { Row,Col } from 'antd-mobile';*/

export default class Main extends React.Component {

    render() {
        return (
            <div style={{ minHeight: '100vh' }}>{this.props.children}</div>
        );
    }
}