import React from 'react'
import './index.less';
import {
  List,
  InputItem,
  WhiteSpace,
  Button,
  Toast,
  WingBlank,
  Tabs,
  Picker,
  TextareaItem,
  Checkbox,
  Flex,
  SearchBar,
  Modal,
  Carousel
} from 'antd-mobile';
import DocumentTitle from './../../components/DocumentTitle';
import { Card } from '../myCard/index';
import { Swiper } from 'antd-mobile-v5';

import Axios from './../../axios';
import { getUrlParam } from "../../utils/utils";
import { Base64 } from "js-base64";
import { extendMoment } from "moment-range";
import Moment from "moment";
const moment = extendMoment(Moment);
const ListItem = List.Item;
const AgreeItem = Checkbox.AgreeItem;
const FlexItem = Flex.Item;
const wx = window.wx;



export default class MyCenter extends React.Component {
  state = {
  }
  componentWillMount() {
    this.urlBase64Request();
    this.userInfoReuest();
    this.cardsReuest();
  }
  /***获取用户信息***/
  userInfoReuest = () => {
    Toast.loading("加载中...", 0)
    Axios.serviceRequest({
      method: 'get',
      url: '/wx/user/detail',
      params: "",
    }).then((data) => {
      //console.log(data)
      let userInfo = data.data;
      this.setState({
        userInfo
      })
    }).catch((error) => {
      console.log(error);
    })
  }
  //临停补缴
  handleTemporaryapture = (item) => {
    let orderType = "TEMPORARY_SUPPLEMENTARY";   //临停补缴
    let prePaymentType = "NONE";   //临停补缴
    let params = {
      parkingCardId: item.id,
      siteId: item.siteId,
      orderType,
      prePaymentType,
    }
    //this.orderNumReuest(params);
    this.renewReuest(params);
  }
  /***查询卡列表***/
  cardsReuest = () => {
    //Toast.loading("加载中...", 0)
    Axios.serviceRequest({
      method: 'get',
      url: '/wx/parkingCard',
      params: "",
    }).then((data) => {
      //console.log(data)
      let cards = data.data;
      this.setState({
        cards
      })
    }).catch((error) => {
      console.log(error);
    })
  }
  /***链接转码-获取签名***/
  urlBase64Request = () => {
    //Toast.loading("加载中...", 0)
    let urlParams = {
      // url: Base64.encode(window.location.href)
      url: Base64.encode(window.location.href.split('#')[0])
    }
    Axios.serviceRequest({
      method: 'get',
      url: '/common/getJsSdkConf',
      params: urlParams,
    }).then((data) => {
      console.log(data)
      let wxSignature = data.data;
      wx.config({
        debug: false,
        appId: wxSignature.appid,
        timestamp: wxSignature.timeStamp,
        nonceStr: wxSignature.nonceStr,
        signature: wxSignature.signature,
        jsApiList: ['chooseWXPay']
      })
      this.setState({
        wxSignature
      })
    }).catch((error) => {
      console.log(error);
    })
  }
  /***订单续费***/
  renewReuest = (params) => {
    let _this = this;
    Toast.loading("数据提交中...", 0)
    Axios.serviceRequest({
      method: 'post',
      url: '/wx/order/renew',
      params: params,
    }).then((data) => {
      Toast.success("数据提交成功！", 1)
      let payData = data.data;
      if (data.object == "DO_NOT_NEED_PAY") {
        //不需要支付
        window.location.reload();
      } else {
        wx.ready(res => {
          wx.checkJsApi({
            jsApiList: ['chooseWXPay'],
            success: res => {
              wx.chooseWXPay({
                ...payData,
                success: function () {
                  //Toast.success("支付成功！", 1,)
                  let _params = {
                    id: payData.orderId
                  }
                  _this.orderStateReuest(_params)
                },
                cancel: function () {
                  let _params = {
                    id: payData.orderId
                  }
                  _this.cancleOrderReuest(_params)
                },
                fail: function (err) {
                  console.log(err)
                }
              })
            },
            fail: err => {
              console.log(err)
            }
          })
        })
      }
      //window.open(`/pc/#/info`,'_self')
    }).catch((error) => {
      console.log(error);
    })
  }
  /*** 获取订单支付状态  微信支付成功回调后执行***/
  orderStateReuest = (params) => {
    Toast.loading("获取支付结果中...", 0)
    let times = this.state.times;
    ++times;
    this.setState({ times })
    Axios.serviceNoErrorRequest({
      method: 'get',
      url: '/wx/order/paidStatus',
      params: params,
    }).then((data) => {
      //console.log(data)
      if (data.data === true) {
        window.location.reload();
      } else {
        if (times > 3) {
          Modal.alert('支付结果异常！', "点击确认按钮关闭页面重试！", [
            { text: '取消', onPress: () => console.log('cancel') },
            {
              text: '确定',
              onPress: () => {
                wx.closeWindow();
              }
            },
          ])
        } else {
          setTimeout(() => {
            this.orderStateReuest(params);
          }, 1500)
        }
      }
    }).catch((error) => {
      console.log(error);
      if (times > 3) {
        Modal.alert('支付结果异常！', "点击确认按钮关闭页面重试！", [
          { text: '取消', onPress: () => console.log('cancel') },
          {
            text: '确定',
            onPress: () => {
              wx.closeWindow();
            }
          },
        ])
      } else {
        Toast.loading("获取支付结果中...", 0)
        setTimeout(() => {
          this.orderStateReuest(params);
        }, 1500)
      }
    })
  }
  /***取消订单***/
  cancleOrderReuest = (params) => {
    Toast.loading("订单取消中...", 0)
    Axios.serviceRequest({
      method: 'post',
      url: '/wx/order/userCancel',
      params: params,
    }).then((data) => {
      Toast.success("订单取消成功！", 1)
    }).catch((error) => {
      console.log(error);
    })
  }
  render() {
    let { userInfo, cards } = this.state;
    return (
      <DocumentTitle title="个人中心">
        <div className="my-center-box">
          <div className="user-info-box" onClick={() => { window.open('/#/myCenter/account', '_self'); }}>
            <span className="left">你好，用户{userInfo && userInfo.mobile}</span>
            <span className="right">我的账号</span>
            <img className="icon" src="/assets/right.png" alt="" />
          </div>
          <div className="my-card-box">
            {
              cards && cards.length > 0 ?
                <Swiper
                  className="card-swiper"
                  loop={false}
                  style={{ '--slide-width': '88%', '--track-offset': '6%' }}
                >
                  {
                    cards.map((item, index) => {
                      return (
                        <Swiper.Item className="card-wrap" key={index}>
                          <Card
                            key={index}
                            item={item}
                            handleTemporaryapture={this.handleTemporaryapture}
                          />
                        </Swiper.Item>
                      )
                    })
                  }
                </Swiper>
                :
                <div className="no-cards">暂无卡片数据！</div>
            }
          </div>
          <List>
            {/*<ListItem*/}
            {/*    arrow="horizontal"*/}
            {/*    thumb="/assets/my_card.png"*/}
            {/*    onClick={() => { window.open(`/#/myCard`,'_self') }}*/}
            {/*>*/}
            {/*    我的卡包*/}
            {/*</ListItem>*/}
            <ListItem
              arrow="horizontal"
              thumb="/assets/my_pay_cost.png"
              onClick={() => { window.open(`/#/agentLogin`, '_self') }}
            >
              代缴费/代绑卡
            </ListItem>
            {/*<ListItem*/}
            {/*    arrow="horizontal"*/}
            {/*    thumb="/assets/icon_cheliang.png"*/}
            {/*    onClick={() => { window.open(`/#/myCar`,'_self') }}*/}
            {/*>*/}
            {/*    我的车辆*/}
            {/*</ListItem>*/}
            <ListItem
              arrow="horizontal"
              thumb="/assets/icon_tuikuan.png"
              onClick={() => { window.open(`/#/refundRecord`, '_self') }}
            >
              退款记录
            </ListItem>
          </List>
          {
            (cards && cards.length > 0) &&
            <WingBlank>
              <div className="txt-danger" style={{ paddingTop: 15 }}>如果你无需再次使用智定义智能车库，请退卡或退费，否则将持续计费！</div>
            </WingBlank>
          }
        </div>
      </DocumentTitle>
    );
  }
}

// class Card extends React.Component {
//   state = {
//     time: 0,
//   }
//   componentWillMount() {
//   }
//   //退卡
//   handleReturnCard = (id, type) => {
//     if (type == "NONE") {
//       Modal.alert('退临时卡', '押金将会退回至你的微信账户！', [
//         { text: '取消', onPress: () => console.log('cancel') },
//         {
//           text: '确定',
//           onPress: () => {
//             let params = {
//               id
//             }
//             this.returnTemCardReuest(params);
//           }
//         },
//       ])
//     } else {
//       /*let ModalAlert = Modal.alert('退卡', <div>在您退还实体卡后，押金和剩余套餐费用将会一起发放至您填写的银行账户中，若实体卡遗失请选择
//           <span className="txt-primary" onClick={() => {
//               ModalAlert.close();
//               window.open(`/#/bankCard?id=${id}`,'_self') ;
//           }}>只退费</span>，否则不能通过审核！</div>,
//           [
//               { text: '取消', onPress: () => console.log('cancel') },
//               {
//                   text: '填写银行账户',
//                   onPress: () =>{
//                       window.open(`/#/bankCard?id=${id}&haveCard=true`,'_self')
//                   }
//               },
//       ])*/
//       let ModalAlert = Modal.alert('退卡',
//         <div>
//           <div>1.在您退还实体卡后，押金和剩余套餐费用将会一起发放至您的微信零钱中，若实体卡遗失请点击
//             <span className="txt-primary" onClick={() => {
//               this.handleReturnCost(id);
//               ModalAlert.close();
//               /*window.open(`/#/bankCard?id=${id}`,'_self') ;*/
//             }}>只退费</span>
//             ，否则不能通过审核！</div>
//           <div>2.<span className="txt-danger">退费前请确保账号已实名认证！</span>未实名认证可能发生异常，我们将会在后台记录并在线下为您处理。</div>
//         </div>,
//         [
//           { text: '取消', onPress: () => console.log('cancel') },
//           {
//             /*text: '填写银行账户',*/
//             text: '确定',
//             onPress: () => {
//               /*window.open(`/#/bankCard?id=${id}&haveCard=true`,'_self')*/
//               this.returnCardReuest({ parkingCardId: id, notCard: false })
//             }
//           },
//         ])
//     }
//   }
//   //退费
//   handleReturnCost = (id) => {
//     Modal.alert('退费',
//       <div>
//         剩余套餐费用将会发放至您的微信零钱中。<span className="txt-danger">退费前请确保账号已实名认证！</span>未实名认证可能发生异常，我们将会在后台记录并在线下为您处理。
//       </div>, [
//       { text: '取消', onPress: () => console.log('cancel') },
//       {
//         text: '确定',
//         onPress: () => {
//           /* window.open(`/#/bankCard?id=${id}`,'_self')*/
//           this.returnCardReuest({ parkingCardId: id, notCard: true });
//         }
//       },
//     ])
//   }
//   //绑卡
//   handleBindCard = (id) => {
//     window.open(`/#/bindCard?id=${id}`, '_self');
//   }
//   //续费
//   handleRenew = (item) => {
//     window.open(`/#/renew?id=${item.id}&siteId=${item.siteId}&siteName=${item.siteName}`, '_self');
//   }
//   /***套餐过期-补缴***/
//   handleFeePayable = (item) => {
//     console.log(item)
//     let siteId = item.siteId;
//     let vehicleTypeId = item.vehicleTypeId;
//     let prePaymentType = item.prePaymentType;
//     let parkingCardId = item.id;
//     let now = moment().format("YYYY-MM-DD");
//     let endTime = item.endTime ? moment(item.endTime).format("YYYY-MM-DD") : moment("2019-06-20").format("YYYY-MM-DD");   //截止时间
//     let OverdueDays = moment(now).diff(moment(endTime), 'days'); //逾期天数
//     Toast.loading("加载中...", 0);
//     Axios.serviceRequest({
//       method: 'get',
//       url: '/wx/site/temporaryPrice',
//       params: { siteId, vehicleTypeId },
//     }).then((data) => {
//       console.log(data)
//       let result = data.data || {};
//       let temporaryUnitPrice = result.temporaryUnitPrice ? parseFloat(result.temporaryUnitPrice) : 0; //临停单价
//       let vehicleUnitPriceId = result.id;
//       Modal.alert('补缴', <div>您已欠费<span className="txt-danger" >￥{temporaryUnitPrice * OverdueDays.toFixed(2)}</span>，请补缴，补缴费用之后及时退卡退费，否则系统仍将继续计费！</div>,
//         [
//           { text: '取消', onPress: () => console.log('cancel') },
//           {
//             text: '确定',
//             onPress: () => {
//               let params = {
//                 count: 1,
//                 orderType: "PREPAYMENT_SUPPLEMENTARY",
//                 prePaymentType,
//                 parkingCardId,
//               }
//               this.FeePayReuest(params);
//             }
//           },
//         ])

//     }).catch((error) => {
//       console.log(error);
//     })
//   };
//   //是否将要到期  这里提前十天
//   isWillExpire = (endTime) => {
//     let now = moment();
//     endTime = moment(endTime);
//     let timeDiff = endTime.diff(now, 's');   //<0  超过现在时间
//     if (timeDiff < 10 * 24 * 60 * 60) {
//       return true;
//     } else {
//       return false;
//     }
//   }
//   //是否逾期
//   isOverdue = (endTime) => {
//     let now = moment();
//     endTime = moment(endTime);
//     let timeDiff = endTime.diff(now, 's');   //<0  超过现在时间
//     if (timeDiff < 0) {
//       return true;
//     } else {
//       return false;
//     }
//   }
//   /*退卡请求---2.0只支持临停退卡*/
//   returnTemCardReuest = (params) => {
//     Toast.loading("退卡中...", 0)
//     Axios.serviceRequest({
//       method: 'post',
//       url: '/wx/parkingCard/tuika',
//       params: params,
//     }).then((data) => {
//       console.log(data)
//       Toast.success("退卡成功！", 1.5, () => {
//         window.location.reload();
//       })
//     }).catch((error) => {
//       console.log(error);
//     })
//   }
//   /***补缴***/
//   FeePayReuest = (params) => {
//     let _this = this;
//     Toast.loading("数据提交中...", 0)
//     Axios.serviceRequest({
//       method: 'post',
//       url: '/wx/order/renew',
//       params: params,
//     }).then((data) => {
//       Toast.success("数据提交成功！", 1)
//       let payData = data.data;
//       if (data.object == "DO_NOT_NEED_PAY") {
//         //不需要支付
//         window.location.reload();
//       } else {
//         wx.ready(res => {
//           wx.checkJsApi({
//             jsApiList: ['chooseWXPay'],
//             success: res => {
//               wx.chooseWXPay({
//                 ...payData,
//                 success: function () {
//                   //Toast.success("支付成功！", 1,)
//                   let _params = {
//                     id: payData.orderId
//                   }
//                   _this.orderStateReuest(_params)
//                 },
//                 cancel: function () {
//                   let _params = {
//                     id: payData.orderId
//                   }
//                   _this.cancleOrderReuest(_params)
//                 },
//                 fail: function (err) {
//                   console.log(err)
//                 }
//               })
//             },
//             fail: err => {
//               console.log(err)
//             }
//           })
//         })
//       }
//     }).catch((error) => {
//       console.log(error);
//     })
//   }
//   /***用户退卡退费***/
//   returnCardReuest = (params) => {
//     Toast.loading("请求中...", 0)
//     Axios.serviceRequest({
//       method: 'post',
//       url: '/wx/parkingCard/parkingCardRefund',
//       params: params,
//     }).then((data) => {
//       console.log(data)
//       Toast.success("退费/退卡申请已成功，请等待工作人员审核！", 2, () => {
//         window.location.reload();
//       })
//     }).catch((error) => {
//       console.log(error);
//     })
//   }
//   /***取消订单***/
//   cancleOrderReuest = (params) => {
//     Toast.loading("订单取消中...", 0)
//     Axios.serviceRequest({
//       method: 'post',
//       url: '/wx/order/userCancel',
//       params: params,
//     }).then((data) => {
//       Toast.success("订单取消成功！", 1)
//     }).catch((error) => {
//       console.log(error);
//     })
//   }
//   /*** 获取订单支付状态  微信支付成功回调后执行***/
//   orderStateReuest = (params) => {
//     Toast.loading("获取支付结果中...", 0)
//     let times = this.state.times;
//     ++times;
//     this.setState({ times })
//     Axios.serviceNoErrorRequest({
//       method: 'get',
//       url: '/wx/order/paidStatus',
//       params: params,
//     }).then((data) => {
//       //console.log(data)
//       if (data.data === true) {
//         window.location.reload();
//       } else {
//         if (times > 3) {
//           Modal.alert('支付结果异常！', "点击确认按钮关闭页面重试！", [
//             { text: '取消', onPress: () => console.log('cancel') },
//             {
//               text: '确定',
//               onPress: () => {
//                 wx.closeWindow();
//               }
//             },
//           ])
//         } else {
//           setTimeout(() => {
//             this.orderStateReuest(params);
//           }, 1500)
//         }
//       }
//     }).catch((error) => {
//       console.log(error);
//       if (times > 3) {
//         Modal.alert('支付结果异常！', "点击确认按钮关闭页面重试！", [
//           { text: '取消', onPress: () => console.log('cancel') },
//           {
//             text: '确定',
//             onPress: () => {
//               wx.closeWindow();
//             }
//           },
//         ])
//       } else {
//         Toast.loading("获取支付结果中...", 0)
//         setTimeout(() => {
//           this.orderStateReuest(params);
//         }, 1500)
//       }
//     })
//   }
//   render() {
//     const { item } = this.props;
//     const prePaymentType = item ? item.prePaymentType : "";
//     let supplementary = item.supplementary; //是否已补缴，已补缴就不能续费
//     let cardType = "";  // 临时 temporary  月卡 month 季卡 season  年卡 year
//     let cardTypeTxt = "";
//     if (prePaymentType == "NONE") {
//       cardType = "temporary";
//       cardTypeTxt = "临时卡";
//     } else if (prePaymentType == "MONTH") {
//       cardType = "month";
//       cardTypeTxt = "月卡";
//     } else if (prePaymentType == "QUARTER") {
//       cardType = "season";
//       cardTypeTxt = "季卡";
//     } else if (prePaymentType == "HALF_YEAR") {
//       cardType = "half_year";
//       cardTypeTxt = "半年卡";
//     } else if (prePaymentType == "YEAR") {
//       cardType = "year";
//       cardTypeTxt = "年卡";
//     }
//     const isWillExpire = this.isWillExpire(item.endTime);
//     let isOverdue = this.isOverdue(item.endTime);    //套餐是否过期
//     return (
//       <WingBlank>
//         <div className="card-item">
//           <div className="card-top">{cardTypeTxt} <span className="card-num">{item.id}</span> </div>

//           <div className="card-center">
//             <div className="info-item">{item.siteName}</div>
//             <div className="info-item">{item.vehicleTypeName}</div>
//             {
//               prePaymentType == "NONE" ?
//                 <div className="info-item">押金余额：{item.cashPledgeBalance}</div> :
//                 <div className="info-item">{item.endTime}到期</div>
//             }
//           </div>
//           {
//             prePaymentType == "NONE" ?
//               <div className="card-bottom">
//                 {
//                   item.cashPledgeBalance >= 0 && <Button className="card-btn" size="small" type="primary" onClick={() => this.handleReturnCard(item.id, prePaymentType)}>退临时卡</Button>
//                 }
//                 {
//                   item.cashPledgeBalance < 0 ? <Button className="card-btn dark" size="small" type="primary" onClick={() => this.props.handleTemporaryapture(item)}>缴费</Button> : null
//                 }
//               </div>
//               : null
//           }
//           {
//             prePaymentType != "NONE" && !item.tuikaAudit ?
//               <div className="card-bottom">
//                 <Button className="card-btn" size="small" onClick={() => { window.open(`/#/agentRepairCard?id=${item.id}`, '_self') }}>补卡</Button>

//                 {
//                   item.entityCardMakeState == "NONE" && !isOverdue &&
//                   <Button className="card-btn" size="small" onClick={() => this.handleReturnCost(item.id)}>退费</Button>
//                 }
//                 {
//                   item.entityCardMakeState != "NONE" && !isOverdue &&
//                   <Button size="small" className="card-btn" onClick={() => this.handleReturnCard(item.id, prePaymentType)}>退卡</Button>
//                 }
//                 {
//                   item.entityCardMakeState == 'WAIT_BIND' && <Button className="card-btn" size="small" onClick={() => this.handleBindCard(item.id)}>绑卡</Button>
//                 }
//                 {
//                   // item.entityCardMakeState == 'VALID' && <Button className="card-btn" size="small" onClick={() => this.handleBindCard(item.id)}>换绑</Button>
//                 }
//                 {
//                   supplementary !== true && <Button className="card-btn dark" size="small" onClick={() => this.handleRenew(item)}>续费</Button>
//                 }
//                 {
//                   isOverdue && <Button className="card-btn dark" size="small" onClick={() => { this.handleFeePayable(item) }}>补缴</Button>
//                 }
//               </div>
//               : null
//           }
//         </div>

//       </WingBlank>
//     );
//   }
// }



