import React from 'react'
import { NavLink } from 'react-router-dom';
import './index.less';
import { List, InputItem, WhiteSpace, Button, Toast, WingBlank, Checkbox } from 'antd-mobile';
import { getUrlParam, FilterParam, getBase64 } from "../../utils/utils";
import DocumentTitle from './../../components/DocumentTitle';
import Axios from "../../axios";
const ListItem = List.Item;

export default class Index extends React.Component {
  state = {
    userInfo: {}
  }
  componentWillMount() {
    this.userInfoRequest();
    this.attachInfoRequest();
  }
  handleChange = (e) => {
    let file = e.target.files[0];
    console.log(file)
    let formData = new FormData();
    formData.append("file", file);
    formData.append("dir", "avatar");
    formData.append("open", false);
    Toast.loading("上传中...", 0)
    Axios.jsonRequest({
      method: 'post',
      url: '/common/singleUpload',
      params: formData,
    }).then((data) => {
      Toast.hide();
      console.log(data)
      let info = data.data || {};
      let key = info.key;
      let url = info.url;
      this.updateRequest({ personalPhoto: url })
    }).catch((error) => {
      console.log(error);
      // Toast.hide();
    })
  }
  /***获取用户信息***/
  userInfoRequest = () => {
    Toast.loading("加载中...", 0)
    Axios.serviceRequest({
      method: 'get',
      url: '/wx/user/detail',
      params: "",
    }).then((data) => {
      //console.log(data)
      let userInfo = data.data;
      this.setState({
        userInfo
      })
    }).catch((error) => {
      console.log(error);
    })
  }
  /***更新照片***/
  updateRequest = (params) => {
    Toast.loading("加载中...", 0)
    Axios.serviceRequest({
      method: 'put',
      url: '/wx/user/personalPhoto',
      params,
    }).then((data) => {
      //console.log(data)
      this.attachInfoRequest()
    }).catch((error) => {
      console.log(error);
    })
  }
  /**获取用户附加信息**/
  attachInfoRequest = () => {
    //Toast.loading("加载中...",0)
    Axios.serviceRequest({
      method: 'get',
      url: '/wx/user/attachInfo',
      params: "",
    }).then((data) => {
      console.log(data)
      let info = data.data;
      let realHeadPortrait = info.realHeadPortrait;
      this.setState({
        realHeadPortrait
      })
    }).catch((error) => {
      console.log(error);
    })
  }
  render() {
    const { userInfo, realHeadPortrait } = this.state;
    //headPortraitUrl
    return (
      <DocumentTitle title="个人照片">
        <div className="avatar-upload">
          <div className="avatar"><img alt="" src={realHeadPortrait ? realHeadPortrait : '/assets/normal_head_portrait.png'} /></div>
          <p className="name">{userInfo.nickname ? userInfo.nickname : userInfo.username}</p>
          <p className="mobile">{userInfo.mobile}</p>
          <div className="btn-box">
            <input type="file" accept="image/*" multiple onChange={this.handleChange} />
            <div className="btn">更新照片</div>
          </div>

        </div>
      </DocumentTitle>
    );
  }
}


