import React from 'react';
import { Icon } from 'antd-mobile';
import parkingImg from '../../assets/parking.png';
import scanImg from '../../assets/scan-btn.png';
import travelImg from '../../assets/travel.png';
import centerImg from '../../assets/center.png';
import IconFont from '../IconFont/index';
import './index.less';


const NavBar = (props) => {
  const { onChange, defaultKey } = props;

  const handleChange = (key) => {
    onChange && onChange(key)
  }

  return (
    <div className="navbar-wrapper">
      <div className={`navbar-item ${defaultKey === 'parking' ? 'active' : ''}`} onClick={() => handleChange('parking')}>
        {/* <img className="navbar-img" src={parkingImg} /> */}
        <IconFont
          type={'icon-tingche'}
          className={'nav-icon'}

        />
        <div className="navbar-text">停车</div>
      </div>
      <div className={`navbar-item scan ${defaultKey === 'scan' ? 'active' : ''}`} onClick={() => handleChange('scan')}>
        <img className="navbar-img" src={scanImg} />
        <div className="navbar-text">扫码充电</div>
      </div>
      <div className={`navbar-item ${defaultKey === 'center' ? 'active' : ''}`} onClick={() => handleChange('center')}>
        {/* <img className="navbar-img" src={centerImg} /> */}
        {/* <Icon type="check" /> */}
        <IconFont
          type={'icon-icon_pc'}
          className={'nav-icon'}

        />
        <div className="navbar-text">我的</div>
      </div>

    </div>
  )
}

export default NavBar;