import React from 'react'
import { NavLink } from 'react-router-dom';
import './index.less';
import DocumentTitle from './../../components/DocumentTitle';
import { Button, List, Modal, Radio, Toast } from "antd-mobile";
import { Base64 } from "js-base64";
import Axios from "../../axios";
import { getUrlParam } from "../../utils/utils";
const wx = window.wx;
const ListItem = List.Item;
const RadioItem = Radio.RadioItem;
const alert = Modal.alert;



export default class Index extends React.Component {
  state = {
    choiceCardModal: false,
    times: 0,    //轮询次数
    tipsShow: false,
  }
  componentWillMount() {
    let urlparams = this.props.location.search;
    let userId = getUrlParam("userId", urlparams);
    let token = getUrlParam("token", urlparams);
    let deviceId = getUrlParam("deviceId", urlparams);
    let port = getUrlParam("port", urlparams);
    let siteId = getUrlParam("siteId", urlparams) && getUrlParam("siteId", urlparams) != 0 ? getUrlParam("siteId", urlparams) : undefined;
    let siteName = getUrlParam("siteName", urlparams);
    if (userId) {
      sessionStorage.setItem("X-User-Id", userId);
    }
    if (token) {
      sessionStorage.setItem("Authorization", token);
    }
    if (!siteId || !deviceId) {
      alert('错误提示', `场地信息出错，请联系管理官！`, [
      ])
      return
    }
    this.urlBase64Request();
    this.setState({
      deviceId, port, siteName, siteId
    }, () => {
      this.cardRequest({ deviceId })
      this.portRequest({ deviceId })
      this.chargePriceRequest({ deviceId, siteId })
      this.getDetailsReuest({ id: siteId })
    })

  }
  handleChoiceCard = () => {
    this.setState({
      choiceCardModal: true
    })
  }
  handleChoiceCardSubmit = (item) => {
    console.log('item', item)
    this.setState({
      chooseCard: item,
      choiceCardModal: false
    })
  }
  handleChoicePort = (item) => {
    this.setState({
      choosePort: item
    })
  }
  handleChoiceChargeCombo = (item) => {
    console.log(item)
    this.setState({
      chooseChargeCombo: item
    })
  }

  getDetailsReuest = (params) => {
    Toast.loading("加载中...", 0)
    Axios.serviceRequest({
      method: 'get',
      url: '/wx/site/detail',
      params: params,
    }).then((data) => {
      //console.log(data)
      let siteDetails = data.data;
      this.setState({
        siteDetails: siteDetails,
      })
    }).catch((error) => {
      console.log(error);
    })
  }
  //开始充电
  handleSubmit = () => {
    const { deviceId, chooseCard, choosePort, chooseChargeCombo } = this.state;
    if (!chooseCard) {
      Toast.fail("请选择套餐！", 1);
      return;
    }
    if (!choosePort) {
      Toast.fail("请选择端口！", 1);
      return;
    }
    if (chooseCard.isTemporary) {
      if (!chooseChargeCombo) {
        Toast.fail("请选择充电套餐！", 1);
        return;
      }
      this.temporaryOrderRequest({ deviceId, chargeComboId: chooseChargeCombo.id, port: choosePort.port });
    } else {
      this.comboOrderRequest({ deviceId, parkingCardId: chooseCard.id, port: choosePort.port });
    }
  }
  /***查询可用套餐***/
  cardRequest = (params) => {
    Toast.loading("加载中...", 0)
    Axios.serviceRequest({
      method: 'get',
      url: '/wx/chargeOrder/chargedParkingCard',
      params: params,
    }).then((data) => {
      console.log(data.data);
      let cards = data.data || [];
      cards.push({
        isTemporary: true,
        id: 0
      })
      this.setState({
        cards,
        chooseCard: cards[0]
      })
    }).catch((error) => {
      console.log(error);
    })
  }
  /***查询端口***/
  portRequest = (params) => {
    //Toast.loading("加载中...", 0)
    Axios.serviceRequest({
      method: 'get',
      url: '/wx/siteCharge/ports',
      params: params,
    }).then((data) => {
      console.log(data.data);
      let ports = data.data || [];
      this.setState({
        ports
      })
    }).catch((error) => {
      console.log(error);
    })
  }
  /***查充电套餐***/
  chargePriceRequest = (params) => {
    //Toast.loading("加载中...", 0)
    Axios.serviceRequest({
      method: 'get',
      url: '/wx/siteCharge/chargeCombo',
      params: params,
    }).then((data) => {
      console.log(data.data);
      let chargeCombo = data.data || [];
      this.setState({
        chargeCombo
      })
    }).catch((error) => {
      console.log(error);
    })
  }
  /***停车卡套餐充电***/
  comboOrderRequest = (params) => {
    Toast.loading("加载中...", 0)
    Axios.serviceRequest({
      method: 'post',
      url: '/wx/chargeOrder/createByParkingCard',
      params: params,
    }).then((data) => {
      let id = data.data;
      this.setState({
        orderId: id
      }, () => {
        this.chargeRequest();
      })
    }).catch((error) => {
      console.log(error);
    })
  }
  /***临时充电***/
  temporaryOrderRequest = (params) => {
    Toast.loading("加载中...", 0)
    Axios.serviceRequest({
      method: 'post',
      url: '/wx/chargeOrder/createChargeCombo',
      params: params,
    }).then((data) => {
      let id = data.data;
      this.setState({
        orderId: id
      }, () => {
        this.payOrderRequest({ id })
      })
    }).catch((error) => {
      console.log(error);
    })
  }
  /***临时充电-支付***/
  payOrderRequest = (params) => {
    Toast.loading("加载中...", 0)
    let _this = this;
    Axios.serviceRequest({
      method: 'post',
      url: '/wx/chargeOrder/payment',
      params: params,
    }).then((data) => {
      let payData = data.data;
      if (data.object == "DO_NOT_NEED_PAY") {
        //不需要支付
        this.chargeRequest();
      } else {
        wx.ready(res => {
          wx.checkJsApi({
            jsApiList: ['chooseWXPay'],
            success: res => {
              wx.chooseWXPay({
                ...payData,
                success: function () {
                  //Toast.success("支付成功！", 1,)
                  _this.orderStateRequest({ id: params.id })
                },
                cancel: function () {
                },
                fail: function (err) {
                  console.log(err)
                }
              })
            },
            fail: err => {
              console.log(err)
              //alert('check api fail:'+JSON.stringify(res))
            }
          })
        })
      }
    }).catch((error) => {
      console.log(error);
    })
  }
  /*** 获取订单支付状态  微信支付成功回调后执行***/
  orderStateRequest = (params) => {
    Toast.loading("获取支付结果中...", 0)
    let times = this.state.times;
    ++times;
    this.setState({ times })
    Axios.serviceNoErrorRequest({
      method: 'get',
      url: '/wx/chargeOrder/paymentStatus',
      params: params,
    }).then((data) => {
      //console.log(data)
      if (data.data === true) {
        this.chargeRequest();
      } else {
        if (times > 3) {
          Modal.alert('支付结果异常！', "点击确认按钮关闭页面重试！", [
            { text: '取消', onPress: () => console.log('cancel') },
            {
              text: '确定',
              onPress: () => {
                wx.closeWindow();
              }
            },
          ])
        } else {
          setTimeout(() => {
            this.orderStateRequest(params);
          }, 1500)
        }
      }
    }).catch((error) => {
      console.log(error);
      if (times > 3) {
        Modal.alert('支付结果异常！', "点击确认按钮关闭页面重试！", [
          { text: '取消', onPress: () => console.log('cancel') },
          {
            text: '确定',
            onPress: () => {
              wx.closeWindow();
            }
          },
        ])
      } else {
        Toast.loading("获取支付结果中...", 0)
        setTimeout(() => {
          this.orderStateRequest(params);
        }, 1500)
      }
    })
  }
  //上电
  chargeRequest = () => {
    Toast.loading("加载中...", 0)
    Axios.serviceRequest({
      method: 'post',
      url: '/wx/chargeOrder/charge',
      params: { id: this.state.orderId },
    }).then((data) => {
      Toast.success("上电成功！", 1,)
      // const uri = encodeURIComponent(`http://zdy.jiangtour.com/#/myHome`);

      // const url = `https://mp.weixin.qq.com/mp/subscribemsg?action=get_confirm&appid=wxeb24ffdd08809898&scene=1000&template_id=UV2FlNrHJnzatspmNa-VEFdZOkY9wocXuigOAwCcs6s&redirect_url=${uri}&reserved=test#wechat_redirect`
      // window.open(url, '_self')

      window.open(`/#/myHome`, '_self');
    }).catch((error) => {
      console.log(error);
    })
  }
  /***链接转码-获取签名***/
  urlBase64Request = () => {
    //Toast.loading("加载中...", 0)
    let urlParams = {
      // url: Base64.encode(window.location.href)
      url: Base64.encode(window.location.href.split('#')[0])
    }
    Axios.serviceRequest({
      method: 'get',
      url: '/common/getJsSdkConf',
      params: urlParams,
    }).then((data) => {
      console.log(data)
      let wxSignature = data.data;
      wx.config({
        debug: false,
        appId: wxSignature.appid,
        timestamp: wxSignature.timeStamp,
        nonceStr: wxSignature.nonceStr,
        signature: wxSignature.signature,
        jsApiList: ['chooseWXPay', 'scanQRCode']
      })
      this.setState({
        wxSignature
      })
    }).catch((error) => {
      console.log(error);
    })
  }

  handleTips = () => {
    this.setState({
      tipsShow: true,
    })
  }
  render() {
    const { tipsShow, siteName, cards, choiceCardModal, chooseCard, ports, choosePort, chargeCombo, chooseChargeCombo, siteDetails } = this.state;
    const cardCofig = {
      'NONE': '临时卡',
      'MONTH': '月卡',
      'QUARTER': '季卡',
      'HALF_YEAR': '半年卡',
      'YEAR': '年卡',
    }
    console.log('siteDetails------', siteDetails);
    return (
      <DocumentTitle title="充电桩">
        <div className="charge-details">
          <List>
            <ListItem
              // arrow="horizontal"
              extra={<span style={{ color: "#333" }}
              >{siteName}</span>}
            >场地</ListItem>
            <ListItem
              onClick={this.handleChoiceCard}
              arrow="horizontal"
              extra={chooseCard ? <span style={{ color: "#333" }}>{chooseCard.prePaymentType ? `${cardCofig[chooseCard.prePaymentType]} ${chooseCard.id}` : '临时充电'}</span> : <span style={{ color: "#999" }}>请选择套餐</span>}
            >套餐选择</ListItem>
            <div className="port-box">
              <div className="title">端口选择</div>
              <div className="port-list">
                {
                  ports && ports.map(item => {
                    if (choosePort && choosePort.port == item.port) {
                      return <span className="active" onClick={() => { this.handleChoicePort(item) }} key={item.port}>{item.port}</span>
                    } else {
                      return <span onClick={() => { this.handleChoicePort(item) }} key={item.port}>{item.port}</span>
                    }
                  })
                }
              </div>
            </div>
            {
              chooseCard && chooseCard.isTemporary && chargeCombo &&
              <div className="charge-time-box">
                <div className="title">充电套餐</div>
                <div className="time-list">
                  {
                    chargeCombo.map(item => {
                      if (chooseChargeCombo && chooseChargeCombo.id == item.id) {
                        return <span className="active" onClick={() => { this.handleChoiceChargeCombo(item) }} key={item.id}>
                          {item.unitPrice}元 {item.chargeType && item.chargeType === 'DEGREE' ? '' : `/${item.second / 3600}小时`}</span>
                      } else {
                        return <span onClick={() => { this.handleChoiceChargeCombo(item) }} key={item.id}>
                          {item.unitPrice}元 {item.chargeType && item.chargeType === 'DEGREE' ? '' : `/${item.second / 3600}小时`}</span>
                      }
                    })
                  }
                </div>

              </div>
            }

          </List>

          {/*<div className="tips">预估费用：￥15.20</div>*/}
          <div className="btn-box">
            <Button type="primary" onClick={this.handleSubmit}>开始充电</Button>
          </div>

          {
            siteDetails?.chargeType === "DEGREE"
            && <div style={{ color: '#fc7c53', margin: '15px 0 0 15px', textDecoration: 'underline' }} onClick={this.handleTips}>功率计费说明</div>
          }

          <Modal
            popup
            visible={tipsShow}
            animationType="slide-up"
            onClose={() => { this.setState({ tipsShow: false }) }}
          >
            {
              siteDetails?.chargeType === "DEGREE"
              && <div>
                <div style={{ whiteSpace: "pre-wrap", padding: '20px 12px', fontSize: '14px' }} dangerouslySetInnerHTML={{ __html: siteDetails?.chargeTypeDesc }}>
                </div>
              </div>
            }
          </Modal>
          <Modal
            popup
            visible={choiceCardModal}
            animationType="slide-up"
            onClose={() => { this.setState({ choiceCardModal: false }) }}
          >
            <List renderHeader={() => <div>套餐选择</div>} className="popup-list">
              {
                cards && cards.map(item => {
                  if (item.isTemporary) {
                    return (
                      <RadioItem key={item.id} onClick={() => { this.handleChoiceCardSubmit(item) }} checked={chooseCard && chooseCard.id === item.id}>
                        <div className="radio-card-list" style={{ padding: "5px 0 10px" }}>
                          <div className="base-info">临时充电</div>
                        </div>
                      </RadioItem>
                    )
                  } else {
                    return (
                      <RadioItem key={item.id} onClick={() => { this.handleChoiceCardSubmit(item) }} checked={chooseCard && chooseCard.id === item.id}>
                        <div className="radio-card-list">
                          {
                            item.prePaymentType == 'NONE' ?
                              <div className="base-info">{cardCofig[item.prePaymentType]}<span>{item.id}</span></div> :
                              <div className="base-info">{cardCofig[item.prePaymentType]}<span>{item.entityCardNo}</span></div>
                          }
                          <div className="type">车辆类型：{item.vehicleTypeName}</div>
                          <div className="end-time">到期时间：{item.endTime}</div>
                        </div>
                      </RadioItem>
                    )
                  }
                })
              }
            </List>
          </Modal>
        </div>
      </DocumentTitle>
    );
  }
}


