import Moment from 'moment';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);

//当月剩余天数，包含今天
export function monthSurplus() {
    const currentDay = parseInt(moment().format('DD'));
    const totleDay = parseInt(moment().daysInMonth());  //当月总天数
    const monthSurplus = totleDay - currentDay + 1;
    return monthSurplus;
}
//本季度剩余天数，包含今天
export function quarterSurplus() {
    const currentDay = moment().format('YYYY-MM-DD');
    const quarterLast = moment().endOf('quarter').format("YYYY-MM-DD");     //本季度最后一天
    const range = moment.range(currentDay, quarterLast);
    const quarterSurplus = range.diff('days') + 1;
    return quarterSurplus;
}

//本半年度剩余天数，包含今天
export function halfYearSurplus() {
    const currentDay = moment().format('YYYY-MM-DD');
    let currentYear = moment().format("YYYY");        //当前年
    let currentMonth = moment().format("MM");       //当前月
    let halfYearLast;  // 本半年度最后一天
    if (parseInt(currentMonth)>6){
        halfYearLast = currentYear + "-12-31";
    }else {
        halfYearLast = currentYear + "-06-30";
    }
    const range = moment.range(currentDay, halfYearLast);
    const halfYearSurplus = range.diff('days') + 1;
    return halfYearSurplus;
}

//本年度剩余天数，包含今天
export function yearSsurplus() {
    const currentDay = moment().format('YYYY-MM-DD');
    const yearLast = moment().endOf('year').format("YYYY-MM-DD");     //本年度最后一天
    const range = moment.range(currentDay, yearLast);
    const yearSsurplus = range.diff('days') + 1;
    return yearSsurplus;
}
//当月总天数
export function monthDays(){
    const monthDays = parseInt(moment().daysInMonth());  //当月总天数
    return monthDays;
}
//本季度总天数
export function quarterDays(){
    const quarterFirst = moment().startOf('quarter').format("YYYY-MM-DD");     //本季度第一天
    const quarterLast = moment().endOf('quarter').format("YYYY-MM-DD");     //本季度最后一天
    const range = moment.range(quarterFirst, quarterLast);
    const quarterDays = range.diff('days') + 1;
    return quarterDays;
}
//本半年总天数
export function halfYearDays(){
    let currentYear = moment().format("YYYY");        //当前年
    let currentMonth = moment().format("MM");       //当前月
    let halfYearFirst,halfYearLast;  //本半年度第一天   本半年度最后一天
    if (parseInt(currentMonth)>6){
        halfYearFirst = currentYear + "-07-01";
        halfYearLast = currentYear + "-12-31";
    }else {
        halfYearFirst = currentYear + "-01-01";
        halfYearLast = currentYear + "-06-30";
    }
    const range = moment.range(halfYearFirst, halfYearLast);
    const halfYearDays = range.diff('days') + 1;
    return halfYearDays;
}

//本年度总天数
export function yearDays() {
    const yearFirst = moment().startOf('year').format("YYYY-MM-DD");     //本年度第一天
    const yearLast = moment().endOf('year').format("YYYY-MM-DD");     //本年度最后一天
    const range = moment.range(yearFirst, yearLast);
    const yearDays = range.diff('days') + 1;
    return yearDays;
}
//获取未来两个自然年内月份
export function yearMonths(){
    const currentDay = moment().format('YYYY-MM-DD');
    const yearLast = moment().endOf('year').format("YYYY-MM-DD");     //本年度最后一天
    const range = moment.range(currentDay, yearLast);
    const monthsCutYear = Array.from(range.by('months'));  //当前年包含月份，包含本月
    let months = monthsCutYear.map(m => m.format('YYYY-MM'));
    const nextYear = moment().year() + 1;
    const nextYearStart = moment(nextYear.toString()).startOf('year').format("YYYY-MM-DD"); //明年第一天
    const nextYearEnd = moment(nextYear.toString()).endOf('year').format("YYYY-MM-DD"); //明年最后一天
    const nextRange = moment.range(nextYearStart, nextYearEnd);
    const monthsNextYear = Array.from(nextRange.by('months'));  //当前年包含月份，包含本月
    monthsNextYear.map(m=>{
        months.push(m.format('YYYY-MM'));
    });
    return months;
}
//获取某个时间点之后n个月[天、年]  数组
export function periodMonths(startTime){
    startTime = moment(startTime).add(1, 'months').format("YYYY-MM-DD");
    const endTime = moment(startTime).add(1, 'year').format("YYYY-MM-DD");
    const range = moment.range(startTime, endTime);
    let months = Array.from(range.by('months'));
        months = months.map(m => m.format('YYYY-MM'));
        return months;
}

export function getUrlParam(key,str){
    var reg = new RegExp("(^|&)"+ key +"=([^&]*)(&|$)");
    var result = str.substr(1).match(reg);
    return result ? decodeURIComponent(result[2]) : null;
}

//处理对象参数值，排除对象参数值为”“、null、undefined，并返回一个新对象
export function FilterParam(obj){
    function dataType(obj){
        if (obj===null) return "Null";
        if (obj===undefined) return "Undefined";
        return Object.prototype.toString.call(obj).slice(8,-1);
    }
    let param = {};
    if ( obj === null || obj === undefined || obj === "" ) return param;
    for ( var key in obj ){
        if ( dataType(obj[key]) === "Object" ){
            param[key] = FilterParam(obj[key]);
        }else if(  obj[key] !== null && obj[key] !== undefined && obj[key] !== ""  ){
            param[key] = obj[key];
        }
    }
    return param;
}

//图片Base64处理
export function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

