import React from 'react'
import { NavLink } from 'react-router-dom';
import './index.less';
import { List, InputItem,WhiteSpace,Button,Toast,WingBlank,Checkbox } from 'antd-mobile';
import { getUrlParam,FilterParam,getBase64 } from "../../utils/utils";
import DocumentTitle from './../../components/DocumentTitle';



export default class ChooseType extends React.Component {
    state = {
        avatar:{}
    }
    componentWillMount() {

    }
    handleChooseType = (e)=>{
        console.log(e)
        let parkType = e;
        if (parkType===2){
            //充电
           // window.open('/#/charge/first','_self');
        }else {
            window.open(`/#/agentPay?parkType=${parkType}`,'_self')
        }
    }

    render() {
        return (
            <DocumentTitle title="代理中心">
                <div className="choose-type">
                    <div className="top-box" onClick={()=>{ window.open('/#/agentCenter','_self');}}>
                        <img className="icon-user" alt="" src="/assets/icon_home.png"/>
                        <span>代理中心</span>
                        <img className="icon-arrow" alt="" src="/assets/icon_homeright.png"/>
                    </div>
                    {/*<div className="item" onClick={()=>this.handleChooseType(0)} style={{paddingTop:0}}>*/}
                    {/*    <div className="box">*/}
                    {/*        <div className="left">*/}
                    {/*            <img alt="" src="/assets/icon_blue.png"/>*/}
                    {/*        </div>*/}
                    {/*        <div className="right">*/}
                    {/*            <p className="title">临时停车</p>*/}
                    {/*            <p className="sub-title">临时停放非机动车</p>*/}
                    {/*            <div className="btn-box">*/}
                    {/*                <Button size="small" type="primary">临停缴费</Button>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="item" onClick={()=>this.handleChooseType(1)}>
                        <div className="box">
                            <div className="left">
                                <img alt="" src="/assets/icon_red.png"/>
                            </div>
                            <div className="right">
                                <p className="title">包月停车</p>
                                <p className="title">（新办卡）</p>
                                <p className="sub-title">包月停车，省心划算</p>
                                <div className="btn-box">
                                    <Button size="small" type="primary">包月缴费</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </DocumentTitle>
        );
    }
}


