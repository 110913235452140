import React from "react";
import "./wechatLogin.less";
import {
  List,
  InputItem,
  WhiteSpace,
  Button,
  Toast,
  WingBlank,
  Checkbox,
} from "antd-mobile";
import { getUrlParam, FilterParam } from "../../utils/utils";
import DocumentTitle from "./../../components/DocumentTitle";
import Axios from "./../../axios";
import LoginImg from "../../assets/loginImg.png";
import { NavLink } from "react-router-dom";
const AgreeItem = Checkbox.AgreeItem;

export default class WechatLogin extends React.Component {
  state = {
    appid: "123",
    url: "www.baidu.com",
  };
  componentWillMount() {}
  wxlogin = ()=> {
    const { appid, url } = this.state;
    window.location.href =  `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${url}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
  }
  //this.appid 公众号APPID  this.url  用户点击授权后，会跳转到后台帮我们重定向的页面（这里我是新建了一个空白页，专门接收code）
  //在重定向的页面拿到微信那边给我们返回的code，会拼接在URL后面，我们拿到这个code再请求后台的接口换取token，完成微信登录

  render() {
    return (
      <DocumentTitle title="">
        <div className="wechatLoginBox">
          <img className="loginImg" src={LoginImg}></img>
          <div className="protocol">
            <AgreeItem
              className="checkBox"
              onChange={this.handleAgreeChange}
              defaultChecked={true}
            >
              <span className="txt">
                已阅读并同意
                <NavLink to={`/register/rules`}>
                  {" "}
                  《法律条款及隐私政策》{" "}
                </NavLink>
              </span>
            </AgreeItem>
          </div>
          <WingBlank>
            <Button
              className="wechatLoginBtn"
              type="primary"
              onClick={this.wxlogin}
            >
              微信用户一键登录
            </Button>
          </WingBlank>
        </div>
      </DocumentTitle>
    );
  }
}
