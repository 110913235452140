import React from "react";
import "./index.less";
import {
  List,
  InputItem,
  WhiteSpace,
  Button,
  Toast,
  WingBlank,
  Checkbox,
} from "antd-mobile";
import { getUrlParam, FilterParam } from "../../utils/utils";
import DocumentTitle from "./../../components/DocumentTitle";
import LoginImg from "../../assets/loginImg.png";
import Axios from "./../../axios";
import { NavLink } from "react-router-dom";
const AgreeItem = Checkbox.AgreeItem;

export default class Register extends React.Component {
  state = {
    initTxt: "获取验证码",
    wait: 0,
    isRegistry: true,
    acceptAgreement: true,
  };
  componentWillMount() {
    //从链接获取参数
    let urlparams = this.props.location.search;
    let siteId = getUrlParam("siteId", urlparams);
    let lockId = getUrlParam("lockId", urlparams);
    let openId = getUrlParam("openId", urlparams);
    let nickname = getUrlParam("nickname", urlparams);
    let headPortraitUrl = getUrlParam("headPortraitUrl", urlparams);
    let userCenter = getUrlParam("userCenter", urlparams);
    let parkType = getUrlParam("parkType", urlparams);
    sessionStorage.setItem("siteId", siteId);
    sessionStorage.setItem("lockId", lockId);
    sessionStorage.setItem("openId", openId);
    sessionStorage.setItem("nickname", nickname);
    sessionStorage.setItem("headPortraitUrl", headPortraitUrl);
    if (userCenter) {
      this.setState({
        userCenter,
      });
    }
    this.setState({
      parkType,
    });
  }
  //各搜索框值
  handleChange = (e, type) => {
    this.setState({
      [type]: e,
    });
    if (type == "mobile") {
      if (/^1(3|4|5|6|7|8|9)\d{9}$/.test(e)) {
        let params = {
          mobile: e,
        };
        this.verifyMobileReuest(params);
      }
    }
  };
  handleAgreeChange = (e) => {
    //console.log(e.target.checked)
    this.setState({
      acceptAgreement: e.target.checked,
    });
  };
  //确定
  handleSubmit = () => {
    const { realname, idcard, mobile, verifyCode, isRegistry, isBind } =
      this.state;
    if (!mobile) {
      Toast.fail("请输入手机号码！", 1);
      return false;
    }
    if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(mobile)) {
      Toast.fail("手机号码不合法！", 1);
      return false;
    }
    if (!verifyCode) {
      Toast.fail("请输入验证码！", 1);
      return false;
    }
    let openId = sessionStorage.getItem("openId");
    // let openId = 'oWNoKw-5visYjxkCxI1SUURoQgbI';
    let nickname = sessionStorage.getItem("nickname");
    let headPortraitUrl = sessionStorage.getItem("headPortraitUrl");
    if (isRegistry) {
      let params = {
        mobile,
        verifyCode,
        openId,
      };
      this.loginReuest(params);
    } else if (!isRegistry && !isBind) {
      if (!realname) {
        Toast.fail("请输入真实姓名！", 1);
        return false;
      }
      /* if (!idcard){
           Toast.fail("请输入身份证号码！",1);
           return false;
       }
       const idCardRegex = /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx])|([1−9]\d5\d2((0[1−9])|(10|11|12))(([0−2][1−9])|10|20|30|31)\d2[0−9Xx])|([1−9]\d5\d2((0[1−9])|(10|11|12))(([0−2][1−9])|10|20|30|31)\d2[0−9Xx])/;
       if(!idCardRegex.test(idcard)){
           Toast.fail("身份证号码不合法！",1);
           return false;
       }*/
      let params = {
        realname,
        mobile,
        verifyCode,
        openId,
        nickname,
        headPortraitUrl,
      };
      params = FilterParam(params);
      this.registerReuest(params);
    }
    // else if (isRegistry && isBind) {
    //   Toast.fail("当前手机号码已绑定微信，请更换手机号码！", 2);
    // }
  };
  //获取验证码
  handleGetCode = () => {
    const { mobile } = this.state;
    if (!mobile) {
      Toast.fail("请输入手机号码！", 1);
      return false;
    }
    if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(mobile)) {
      Toast.fail("手机号码不合法！", 1);
      return false;
    }
    let params = {
      type: "IDENTITY_AUTHENTICATION",
      mobile,
    };
    this.getCodeReuest(params);
    this.setState(
      {
        wait: 60,
      },
      () => {
        this.countdown();
      }
    );
  };
  //倒计时
  countdown = () => {
    let { wait } = this.state;
    if (wait > 0) {
      wait--;
      this.setState({
        wait,
      });
      setTimeout(this.countdown, 1000);
    }
  };
  /*获取验证码请求*/
  getCodeReuest = (params) => {
    Toast.loading("获取验证码中...", 0);
    Axios.serviceRequest({
      method: "post",
      url: "/common/verifyCode",
      params: params,
    })
      .then((data) => {
        console.log(data);
        Toast.success("验证码获取成功,请注意查收！", 1);
      })
      .catch((error) => {
        console.log(error);
        // Toast.hide();
      });
  };
  /*登录请求*/
  loginReuest = (params) => {
    Toast.loading("登录中...", 0);
    Axios.serviceRequest({
      method: "post",
      url: "/wx/user/login",
      params: params,
    })
      .then((data) => {
        console.log(data);
        //Toast.success("登录成功！", 1)
        sessionStorage.setItem("Authorization", data.object);
        sessionStorage.setItem("X-User-Id", data.data);
        const { parkType } = this.state;
        window.open(`/#/pay?parkType=${parkType}`, "_self");
        // this.attachInfoRequest();
        /*let userCenter = this.state.userCenter;
      if (userCenter){
          window.open(`/#/myCenter`,'_self')
      } else {
          window.open(`/#/pay`,'_self')
      }*/
      })
      .catch((error) => {
        console.log(error);
        // Toast.hide();
      });
  };

  /*注册请求*/
  registerReuest = (params) => {
    Toast.loading("注册中...", 0);
    Axios.serviceRequest({
      method: "post",
      url: "/wx/user/registry",
      params: params,
    })
      .then((data) => {
        console.log(data);
        Toast.success("注册成功！", 1);
        sessionStorage.setItem("Authorization", data.object);
        sessionStorage.setItem("X-User-Id", data.data);
        const { parkType } = this.state;
        window.open(`/#/pay?parkType=${parkType}`, "_self");
        // this.attachInfoRequest();
        /*let userCenter = this.state.userCenter;
      if (userCenter){
          window.open(`/#/myCenter`,'_self')
      } else {
          window.open(`/#/pay`,'_self')
      }*/
      })
      .catch((error) => {
        console.log(error);
        // Toast.hide();
      });
  };
  /*验证手机号码是否注册绑定*/
  verifyMobileReuest = (params) => {
    Toast.loading("校验中...", 0);
    Axios.serviceRequest({
      method: "get",
      url: "/wx/user/verifyMobileRegistryAndBindStatus",
      params: params,
    })
      .then((data) => {
        console.log(data);
        let isRegistry = data.data; //是否已注册
        let isBind = data.object == "true" ? true : false; //是否已绑定
        this.setState({
          isRegistry,
          isBind,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  /**获取用户附加信息**/
  attachInfoRequest = () => {
    Toast.loading("加载中...", 0);
    Axios.serviceRequest({
      method: "get",
      url: "/wx/user/attachInfo",
      params: "",
    })
      .then((data) => {
        console.log(data);
        let info = data.data;
        let acceptAgreement = info.acceptAgreement;
        let realHeadPortrait = info.realHeadPortrait;
        let userCenter = this.state.userCenter ? this.state.userCenter : "";
        // if (acceptAgreement&&realHeadPortrait){
        //     if (userCenter){
        //         window.open(`/#/myCenter`,'_self')
        //     } else {
        //         window.open(`/#/pay`,'_self')
        //     }
        // }else {
        //     window.open(`/#/register/completeInfo?userCenter=${userCenter}`,'_self')
        // }
        if (userCenter) {
          window.open(`/#/myCenter`, "_self");
        } else {
          window.open(`/#/pay`, "_self");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  render() {
    const { wait, isRegistry, acceptAgreement } = this.state;
    return (
      <DocumentTitle title="">
        <div className="register-box">
          <img className="loginImg" src={LoginImg}></img>
          <List>
            <InputItem
              className="loginInput"
              onChange={(e) => this.handleChange(e, "mobile")}
              type="number"
              placeholder="请输入手机号"
            ></InputItem>

            <InputItem
              className="loginInput"
              onChange={(e) => this.handleChange(e, "verifyCode")}
              extra={
                wait > 0 ? (
                  <a className="btn-txt-disabled">
                    {wait}s后重新发送
                  </a>
                ) : (
                  <div className="getCodeBox">
                    <span>|</span>
                    <a
                      className="btn-txt-primary"
                      onClick={this.handleGetCode}
                    >
                      获取验证码
                    </a>
                  </div>
                )
              }
              type="text"
              placeholder="请输入验证码"
            ></InputItem>
            {!isRegistry ? (
              <div>
                <InputItem
                  type="text"
                  onChange={(e) => this.handleChange(e, "realname")}
                  placeholder="请输入真实姓名"
                >
                  姓名
                </InputItem>

                {/*<InputItem
                                        onChange={(e)=>this.handleChange(e,"idcard")}
                                        type="text"
                                        placeholder="请输入身份证号码"
                                    >身份证号</InputItem>*/}
              </div>
            ) : null}
          </List>
          <WhiteSpace size="xl" />
          <WingBlank>
            <Button
              type="primary"
              disabled={!acceptAgreement}
              onClick={this.handleSubmit}
            >
              登录
            </Button>
          </WingBlank>
          <div className="protocol">
            <AgreeItem className="checkBox" onChange={this.handleAgreeChange} defaultChecked={true}>
              <span className="txt">
                同意非机动<NavLink to={`/register/rules`}> 车库管理规定 </NavLink>
                与<NavLink to={`/register/standard`}> 赔偿标准 </NavLink>
              </span>
            </AgreeItem>
          </div>
        </div>
      </DocumentTitle>
    );
  }
}
